import React, { useState } from "react";
import "./accordionmenu.css";
import Images from "../../images";

const AccordionMenu = (props) => {
  const {
    customeStyle,
    energyComplianceData,
    locationData,
    openSection,
    toggleSection,
    handleCheckboxChange,
    energyStarZone,
    complianceData,
    Ishelp,
  } = props;
  const [isOpenSection, setIsOpenSection] = useState(false);

  let ieccData;
  if (locationData && locationData.IECC21 === "4") {
    if (locationData.Moisture21 === "C") {
      ieccData =
        energyComplianceData &&
        energyComplianceData.iecc_2021 &&
        energyComplianceData.iecc_2021[4];
    } else {
      ieccData =
        energyComplianceData &&
        energyComplianceData.iecc_2021 &&
        energyComplianceData.iecc_2021[3];
    }
  } else {
    ieccData =
      energyComplianceData &&
      energyComplianceData.iecc_2021 &&
      energyComplianceData.iecc_2021[
      parseInt(locationData && locationData.IECC21) - 1
      ];
  }

  const openlocalsection = () => {
    setIsOpenSection((prevState) => !prevState);
  };

  return (
    <div className={`${customeStyle ? "details_energy_btn" : ""} accordion`}>
      <div className="accordion-main">
        <div
          className={`accordion-header ${isOpenSection ? "open" : "closed"}`}
          onClick={() => openlocalsection()}
        >
          <h5>Local Energy Prices</h5>
          <button className="accordion-toggle">
          </button>
        </div>
        <div
          className={`accordion-content ${isOpenSection ? "open" : "closed"}`}
        >
          <div className="other-details">
            <div className="box">
              <div className="box-inner">
                <div className="imgage">
                  <img src={Images && Images.gasIcon} alt="img" />
                </div>
                <div className="sub-title">Gas</div>
              </div>
              <p>
                {locationData && locationData.winter_average ? (
                  <strong>
                    {locationData && locationData.winter_average}{" "}
                  </strong>
                ) : (
                  ""
                )}
                $/thousand ft<sup>3</sup>{" "}
              </p>
            </div>
            <div className="box">
              <div className="box-inner">
                <div className="imgage">
                  <img src={Images && Images.electriceIcon} alt="img" />
                </div>
                <div className="sub-title">Electricity</div>
              </div>
              <p>
                {locationData && locationData.electric_value ? (
                  <strong>
                    {locationData && locationData.electric_value}{" "}
                  </strong>
                ) : (
                  ""
                )}
                $/kWh
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${Ishelp ? "showborder" : ""} accordion-main`}>
        <div
          className={`accordion-header ${openSection === 0 || openSection === 1 ? "open" : "closed"
            }`}
        >
          <h5>Choose Code Compliance Path</h5>
        </div>

        <div className={"accordion-content open"}>
          <div className="compliance-table-wrap">
            <ul className="checkbox-custom">
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={openSection === 0}
                    onChange={handleCheckboxChange}
                    value="Prescriptive"
                    onClick={() => toggleSection(0, complianceData)}
                  />
                  ENERGY STAR 7 Program<span className="checkmark"></span>
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={openSection === 1}
                    onChange={handleCheckboxChange}
                    value="Energy Equivalent"
                    onClick={() => toggleSection(1, ieccData)}
                  />
                  IECC 2021 Energy Code
                  <span className="checkmark"></span>
                </label>
              </li>
            </ul>

            {openSection === 0 && complianceData && (
              <div className="table-responsive">
                <table>
                  <caption>
                    {energyStarZone}{" "}
                    {energyStarZone === "Northern" ? "Prescriptive" : "Zone"}
                  </caption>
                  <thead>
                    <tr>
                      <th>U-Factor</th>
                      <th>SHGC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {complianceData.prescriptive_path ? (
                      <tr>
                        <td>{complianceData.prescriptive_path.u_factor}</td>
                        <td>{complianceData.prescriptive_path.shgc}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{complianceData.u_factor}</td>
                        <td>{complianceData.shgc}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {complianceData.energy_equivalent_path &&
                  complianceData.energy_equivalent_path ? (
                  <div className="table-responsive table-equivalent">
                    <table>
                      <caption>Northern Energy Equivalent</caption>
                      <thead>
                        <tr>
                          <th>U-Factor</th>
                          <th>SHGC</th>
                        </tr>
                      </thead>
                      <tbody>
                        {complianceData.energy_equivalent_path &&
                          complianceData.energy_equivalent_path.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                {item.u_factor.map((uFactor, uIndex) => (
                                  <tr key={uIndex}>
                                    <td>{uFactor}</td>
                                    {uIndex === 0 && (
                                      <td rowSpan={item.u_factor.length}>
                                        {item.shgc}
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </React.Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {openSection === 1 && ieccData && (
              <div className="table-responsive iecc-table">
                <table>
                  <caption>
                    {locationData.IECC21 === "4" &&
                      locationData.Moisture21 === "C"
                      ? "IECC 2021 Zone 5"
                      : locationData.IECC21 === "4"
                        ? "IECC 2021 4 Marine"
                        : `IECC 2021 Zone ${locationData.iecc21_moisture21}`}
                  </caption>

                  <thead>
                    <tr>
                      <th>U-Factor</th>
                      <th>SHGC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ieccData["u_factor"]}</td>
                      <td>{ieccData["shgc"]}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionMenu;
