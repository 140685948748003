import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../styles/energy-calculator-location.css";
import ChartComponent from "../../components/EnergyChart";
import AccordionMenu from "../../components/AccordionMenu";
import EnergyLogo from "../../components/EnergyCalLogo";
import Images from "../../images";
import Cookies from "js-cookie";
import CalculatorScene from "../../components/CalculatorScene";
import { EngineEvents } from "cardinalcalculatorscene";

var API_BASE_URI = window.wp_config.api_uri;
let controller, signal;

const EnergyLocation = ({ isLandscape }) => {
  let token = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
  const [ActiveConfirm, setActiveConfirm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const chartRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prevzipcode = searchParams.get("zipcode");
  const [zipcode, setZipCodes] = useState("");
  const [locationData, setLocationData] = useState("");
  const [ActiveListDetails, setActiveListDetails] = useState(false);
  const [chartData, setChartData] = useState("");
  const [showSidebarMap, setSidebarMap] = useState(false);
  let body = document?.querySelector("body");
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [filteredCities, setFilteredCities] = useState();
  const [activeButton, setActiveButton] = useState("zip-code");
  const [activeclass, setActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeText, setActiveText] = useState("Energy");
  const [showNonCompliant, setShowNonCompliant] = useState(false);
  const [energyComplianceData, setEnergyComplianceData] = useState({});
  const [openSection, setOpenSection] = useState(null);
  const [isPrescriptiveChecked, setIsPrescriptiveChecked] = useState(true);
  const [isMobile, setIsmobile] = useState(false);
  const [isMac, setIsmac] = useState(false);
  const [comfortdata, setComfortData] = useState();
  const [isMapInstance, setMap] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const [zonedata, setZoneData] = useState([]);
  const [zonewithclr, setZoneWithColor] = useState([]);
  const [iecczoneclrs, setIeccZoneColors] = useState({});
  const [hoveredBarPosition, setHoveredBarPosition] = useState();
  const [IsFourK, setIsFourK] = useState();
  const [Ishelp, setHelpPopup] = useState(null);
  const [visiblePoup, setVisiblePoup] = useState(null);
  const [isPortrait, setIsPortrait] = useState(null);
  const [isPopupCentred, setIsPopupCentred] = useState(false);
  const [view3d, set3d] = useState(false);
  const [cardinalCalSceneInstance, setCardinalCalSceneInstance] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef(null);

  const basePath = process.env.REACT_APP_BASE_PATH || "";
  const pagePath = process.env.REACT_APP_PAGE_PATH || "";

  const energyStarZone = locationData && locationData.energy_star_zone;
  const complianceData =
    energyComplianceData &&
    energyComplianceData?.energy_star_7 &&
    energyComplianceData?.energy_star_7[energyStarZone];

  const toggleSection = (index, data) => {
    setOpenSection(index === openSection ? null : index);
    if (index === 0) {
      setIsPrescriptiveChecked(true);
    }
  };

  const handleexclamationClick = (idx) => {
    setVisiblePoup(idx === visiblePoup ? null : idx);
    setHoveredBarPosition(null);
  };
  const handleCloseDiscPopup = () => {
    setVisiblePoup(null);
  };

  const handleCheckboxChange = (event) => {
    setIsPrescriptiveChecked(event.target.value === "Prescriptive");
  };
  useEffect(() => {
    if (showSidebarMap && isMapInstance) {
      setTimeout(() => {
        isMapInstance.resize();
      }, 500);
    }
  }, [showSidebarMap]);

  useEffect(() => {
    window.scrollTo(0, 0);
    function mobileResponsiveCheck() {
      if (window.innerWidth <= 768) {
        setIsmobile(true);
      }
    }
    function macResponsiveCheck() {
      if (window.innerWidth <= 1500) {
        setIsmac(true);
      }
    }
    function fourKResponsiveCheck() {
      if (window.innerWidth >= 3100) {
        setIsFourK(true);
      }
    }
    function CheckWindowOrientation() {
      let isLandscape =
        window?.screen?.orientation?.type === "landscape-primary";

      if (
        window.innerWidth > 800 &&
        window.innerWidth < window.innerHeight &&
        !isLandscape
      ) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    }

    function SystemHeightWidthCheck() {
      if (window.innerWidth < window.innerHeight) {
        setIsPopupCentred(true);
      }
    }
    mobileResponsiveCheck();
    macResponsiveCheck();
    fourKResponsiveCheck();
    CheckWindowOrientation();
    SystemHeightWidthCheck();
    window.addEventListener("resize", mobileResponsiveCheck);
    window.addEventListener("resize", macResponsiveCheck);
    window.addEventListener("resize", fourKResponsiveCheck);
    window.addEventListener("resize", CheckWindowOrientation);
    window.addEventListener("resize", SystemHeightWidthCheck);

    return () => {
      window.removeEventListener("resize", mobileResponsiveCheck);
      window.removeEventListener("resize", macResponsiveCheck);
      window.removeEventListener("resize", fourKResponsiveCheck);
      window.removeEventListener("resize", CheckWindowOrientation);
      window.removeEventListener("resize", SystemHeightWidthCheck);
    };
  }, [isMobile, isMac, isPortrait, isPopupCentred]);

  useEffect(() => {
    const fetchData = async () => {
      if (prevzipcode) {
        try {
          const response = await axios.get(
            `${API_BASE_URI}/wp-json/energy-calculator/v1/get-location-data/?zipcode=${prevzipcode}`
          );
          if (response?.data?.status === true) {
            setLocationData(response.data?.items[0]);
            setChartData(response?.data?.chart_data);
            let lat = response?.data?.items[0].lat || "44.977964";
            let lng = response?.data?.items[0].lng || "-93.767367";
            let zones = response?.data?.items;
            let zonewithcolors = response?.data?.zone_colors;
            let iecc_colors = response?.data?.iecc_colors;
            mapboxgl.accessToken = token;
            const map = new mapboxgl.Map({
              style: "mapbox://styles/cardinalglass/clpgy67zc007z01p71ppw6ngz",
              container: "energy-map",
              center: [lng, lat],
              zoom: 4,
            });
            setMap(map);
            setZoneData(zones);
            setZoneWithColor(zonewithcolors);
            setIeccZoneColors(iecc_colors);
            map?.on("load", () => {});
          } else return () => map.remove();
        } catch (error) {
          toast.error(error?.response?.data?.message);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [prevzipcode, token]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    let marker;
    const timeoutId = setTimeout(() => {
      let uniqueZones = zonedata.filter(
        (zone, index, self) =>
          index === self.findIndex((t) => t.IECC21 === zone.IECC21)
      );

      if (isMapInstance) {
        marker = new mapboxgl.Marker({
          color: "black",
        })
          .setLngLat([
            parseFloat(uniqueZones[0]?.lng),
            parseFloat(uniqueZones[0]?.lat),
          ])
          .addTo(isMapInstance);

        marker.getElement().addEventListener("click", handleClick);

        if (isMapInstance._popups[0]) {
          isMapInstance._popups[0].remove();
        }

        const popupContent =
          openSection === null
            ? `<h3>${locationData.primary_city}, ${locationData.state_id}</h3>`
            : openSection === 1
            ? `${
                uniqueZones[0].IECC21 === "4" &&
                uniqueZones[0].Moisture21 === "C"
                  ? `<h3>IECC 2021 Zone 5</h3>`
                  : uniqueZones[0].IECC21 === "4"
                  ? `<h3>${locationData.primary_city}, ${locationData.state_id} <br/> IECC 2021 <br/> Zone 4 Marine</h3>`
                  : `<h3>${locationData.primary_city}, ${locationData.state_id} <br/> IECC 2021 <br/> Zone ${uniqueZones[0].iecc21_moisture21}</h3>`
              }`
            : `<h3>${locationData.primary_city}, ${locationData.state_id} <br/> ENERGY STAR 7 <br/> ${uniqueZones[0].energy_star_zone} Zone</h3>`;

        const popup = new mapboxgl.Popup({ closeOnClick: false }).setHTML(
          popupContent
        );
        popup.addTo(isMapInstance);
        marker.setPopup(popup);
      }

      const applyMapProperties = (layerId, property, value) => {
        isMapInstance?.setPaintProperty(layerId, property, value);
      };

      if (openSection === null && isMapInstance) {
        let energyLayerId = process.env.REACT_APP_ENERGY_LAYER_ID;
        let ieccLayerId = process.env.REACT_APP_IECC_LAYER_ID;
        [energyLayerId, ieccLayerId].forEach((layerId) => {
          applyMapProperties(layerId, "fill-opacity", 0);
          applyMapProperties(layerId, "fill-color", "rgba(0,0,0,0)");
        });
      }

      if (openSection === 1 && isMapInstance) {
        let energyLayerId = process.env.REACT_APP_ENERGY_LAYER_ID;
        let ieccLayerId = process.env.REACT_APP_IECC_LAYER_ID;
        [energyLayerId, ieccLayerId].forEach((layerId) => {
          applyMapProperties(layerId, "fill-opacity", 0);
          applyMapProperties(layerId, "fill-color", "rgba(0,0,0,0)");
        });

        applyMapProperties(ieccLayerId, "fill-opacity", [
          "case",
          ["==", ["get", "IECCMoistu"], uniqueZones[0].iecc21_moisture21],
          1,
          0.2,
        ]);

        const filter = ["match", ["get", "IECCMoistu"]];
        for (const label in iecczoneclrs) {
          if (iecczoneclrs.hasOwnProperty(label)) {
            const color = iecczoneclrs[label];
            filter.push(label, color);
          }
        }
        filter.push("grey");
        applyMapProperties(ieccLayerId, "fill-color", filter);
      } else if (openSection === 0 && isPrescriptiveChecked && isMapInstance) {
        let energyLayerId = process.env.REACT_APP_ENERGY_LAYER_ID;
        let ieccLayerId = process.env.REACT_APP_IECC_LAYER_ID;
        [energyLayerId, ieccLayerId].forEach((layerId) => {
          applyMapProperties(layerId, "fill-opacity", 0);
          applyMapProperties(layerId, "fill-color", "rgba(0,0,0,0)");
        });

        applyMapProperties(energyLayerId, "fill-opacity", [
          "case",
          ["==", ["get", "ENERGY STA"], uniqueZones[0].energy_star_zone],
          1,
          0.2,
        ]);

        const filter = ["match", ["get", "ENERGY STA"]];
        zonewithclr.forEach((zone) => {
          filter.push(zone.zone, zone.color);
        });
        filter.push("gray");
        applyMapProperties(energyLayerId, "fill-color", filter);
      }
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      if (marker) {
        const element = marker.getElement();
        element.removeEventListener("click", handleClick);
      }
    };
  }, [
    openSection,
    isMapInstance,
    isPrescriptiveChecked,
    zonedata,
    locationData,
    iecczoneclrs,
    zonewithclr,
  ]);

  const closeModal = () => {
    setIsModalOpen(false);
    if (window.innerWidth >= 768) {
      document.body.style.overflow = "auto";
    }
  };

  /*******handle Details Button***** */
  const handleListButton = () => {
    setActiveListDetails(false);
    // body.style.overflow = "hidden";
  };
  const handleMapView = () => {
    setActiveListDetails(true);
    // body.style.overflow = "auto";
  };

  const handleModalClose = () => {
    setPreviewImage(previewImage);
    closeModal();
  };

  const handleEditClick = () => {
    if (window.innerWidth <= 767) {
      setEditing(true);
      setSidebarMap(false);
      setActiveListDetails(false);
      setSearchTerm(locationData?.primary_city + ", " + locationData?.state_id);
    } else {
      setEditing(true);
      setSearchTerm(locationData?.primary_city + ", " + locationData?.state_id);
      setActiveListDetails(false);
      setOpenSection(null);
    }
  };
  const handleHelpButton = () => {
    setHelpPopup(!Ishelp);
  };

  // first firstpopup
  useEffect(() => {
    const shownCount = Cookies.get("firstpopupShown");
    if (shownCount === "1") {
      return;
    }
    const timeoutId = setTimeout(() => {
      document?.querySelector(".help-popup").classList.add("slide-left");
      setHelpPopup(true);
      Cookies.set("firstpopupShown", "1");
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  // second secondpopup
  useEffect(() => {
    const shownCount = Cookies.get("secondpopupShown");
    if (shownCount === "1") {
      return;
    }
    if (ActiveConfirm && activeText === "Energy") {
      const timeoutId = setTimeout(() => {
        document?.querySelector(".help-popup").classList.add("slide-left");
        setHelpPopup(true);
        Cookies.set("secondpopupShown", "1");
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [ActiveConfirm]);

  // second thirdpopup
  useEffect(() => {
    const shownCount = Cookies.get("thirdpopupShown");
    if (shownCount === "1") {
      return;
    }
    if (ActiveConfirm && activeText === "Comfort") {
      const timeoutId = setTimeout(() => {
        document?.querySelector(".help-popup").classList.add("slide-left");
        setHelpPopup(true);
        Cookies.set("thirdpopupShown", "1");
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [ActiveConfirm && activeText === "Comfort"]);

  // default
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document?.querySelector(".help-popup").classList.remove("slide-left");
      setHelpPopup(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [Ishelp]);

  async function handleChange(event) {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredCities([]);
    const currentAbortController = new AbortController();
    setAbortController(currentAbortController);
    const username = "cardinal";
    const password = "cardinal";
    if (value?.trim()) {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      signal = controller.signal;
      fetch(
        `${API_BASE_URI}/wp-json/energy-calculator/v1/get-cities?search=` +
          value,
        {
          signal: signal,
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setIsSearched(true);
          setFilteredCities(response?.items);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    setActiveButton(value ? "city" : "zip-code");
    setActive(false);
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }

  const getZipcode = (item) => {
    setSearchTerm(item?.city + ", " + item?.state_id);
    setActive(true);
    setZipCodes(item?.zip);
    setFilteredCities([]);
  };

  const handleCloseMap = () => {
    setActiveListDetails(false);
    handleListButton();
  };

  const backButton = () => {
    if (ActiveListDetails) {
      setActiveListDetails(false);
    } else {
      navigate(`${basePath}${pagePath}`);
      cardinalCalSceneInstance?.startOver(EngineEvents.SCENE_DISPOSE, () => {
        console.log("startOver");
      });
    }
  };

  useEffect(() => {
    fetchEnergyComplianceData();
  }, []);

  const fetchEnergyComplianceData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URI}/wp-json/energy-calculator/v1/get-energy-compliance-data`
      );
      setEnergyComplianceData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const chartBackButton = () => {
    setEditing(false);
    setActiveConfirm(false);
    setSidebarMap(false);
    setActive(false);
    handleCloseDiscPopup();
    if (cardinalCalSceneInstance) {
      setIsLoaded(true);
      console.log("true1")
    }
  };

  const viewComfort = (buttonType, barData) => {
    setActiveText(buttonType);
    let label = "";
    if (barData?.label === "HSLE") {
      label = "High Solar Gain LoĒ Products";
    } else if (barData?.label === "MSLE") {
      label = "Medium Solar Gain LoĒ Products";
    } else if (barData?.label === "LSLE") {
      label = "Low Solar Gain LoĒ Products";
    } else {
      label = barData?.label ? barData?.label : "Low Solar Gain LoĒ Products";
    }
    let filterDefaultBar;
    let pType =
      energyStarZone === "Northern" ? "3 pane w/2 LoĒ" : "2 pane w/1 LoĒ+i89";
    Object.entries(chartData && chartData).forEach(([key, value]) => {
      filterDefaultBar = value.find((opt) => {
        return opt.product_type === pType && opt?.product_label === label;
      });
    });
    let payload = {
      chartData: barData?.chartData ? barData?.chartData : chartData,
      label: label,
      productType: barData?.productType ? barData?.productType : pType,
      value: barData?.value ? barData?.value : filterDefaultBar?.value,
      hoveredBarPosition: hoveredBarPosition,
    };
    set3d(true);
    setComfortData(payload);
    setHoveredBarPosition(null);
  };

  const handleChartBtnClick = (buttonType) => {
    setActiveText(buttonType);
    handleCloseDiscPopup();
    if (cardinalCalSceneInstance) {
      setIsLoaded(true);
      console.log("true2")
    }
  };

  const handleSidebarToggle = () => {
    if (!editing) {
      setSidebarMap((prevState) => !prevState);
      setActiveConfirm(false);
      setActiveText("Energy");
      setHoveredBarPosition(null);
      handleCloseDiscPopup();
      if (cardinalCalSceneInstance) {
        setIsLoaded(true);
        console.log("true3")
      }
    } else {
      setEditing(false);
      setOpenSection(false);
      handleCloseDiscPopup();
      if (cardinalCalSceneInstance) {
        setIsLoaded(true);
        console.log("true4")
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <div
        className={`energy-cal-location ${
          showSidebarMap ? "sidebar-animate" : ""
        }`}
      >
        <div
          className={`energy-location-left ${
            ActiveListDetails ? "active" : ""
          }  ${editing ? "location-change" : ""}`}
        >
          <div className="crd-logo">
            <Link href={`${API_BASE_URI}`}>
              <img src={Images.crdLogo} alt="logo" />
            </Link>
          </div>
          <div className="emergy-title-location-wrap">
            <div className="back_button">
              {!editing && !showSidebarMap ? (
                <div onClick={backButton}>
                  <div className="back_arrow">
                    <img
                      src={Images && Images.backArrow}
                      alt="img"
                      className="back_arrow_img"
                    />
                    <span className="dektop-title">Back</span>
                    <span className="mobile-title">Home</span>
                  </div>
                </div>
              ) : !showSidebarMap && editing ? (
                <div
                  onClick={() => {
                    setEditing(false);
                    cardinalCalSceneInstance?.startOver(
                      EngineEvents.SCENE_DISPOSE,
                      () => {
                        console.log("startOver");
                      }
                    );
                  }}
                >
                  <div className="back_arrow">
                    <img
                      src={Images && Images.backArrow}
                      alt="img"
                      className="back_arrow_img"
                    />
                    <span className="dektop-title">Back</span>
                    <span className="mobile-title">Home</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <button
              type="button"
              className="sidebar-toggle-icon"
              onClick={handleSidebarToggle}
            >
              <img
                src={!editing ? Images.toggleIconSidebar : Images.crossIcon}
                alt="img"
              />
            </button>
            <div
              className={`en_list_icon ${ActiveListDetails ? "active" : ""}`}
            >
              <span onClick={handleListButton}>
                <span className="list-title">Details</span>
              </span>
            </div>
            {/** show details end **/}

            {/* /*mobile view map-view button  */}
            <div
              className={`en-map-view-wrap ${ActiveListDetails ? "hide" : ""}`}
            >
              <div className="mapview-icon">
                <span onClick={handleMapView}>
                  <img src={Images && Images.mapIcon} alt="img" />{" "}
                  <span className="list-title">Map view</span>
                </span>
              </div>
            </div>
            {/* /*mobile view map-view end  */}
          </div>

          {!editing && !showSidebarMap ? (
            <React.Fragment>
              <div className="emergy-cal-location" onClick={handleEditClick}>
                <div className="location_icon">
                  <img src={Images && Images.locationIcon} alt="img" />
                </div>
                <h3>
                  {locationData.primary_city}, {locationData.state_id}
                </h3>
                <div className="back-arrow-location">
                  <img src={Images.backArrowIcon} alt="img" />
                </div>
              </div>
              <div className="details_energy_btn_wrap">
                <div className="details_wraps_main">
                  <AccordionMenu
                    customeStyle={"details_energy_btn"}
                    energyComplianceData={energyComplianceData}
                    locationData={locationData}
                    openSection={openSection}
                    toggleSection={toggleSection}
                    isPrescriptiveChecked={isPrescriptiveChecked}
                    handleCheckboxChange={handleCheckboxChange}
                    energyStarZone={energyStarZone}
                    complianceData={complianceData}
                    Ishelp={Ishelp}
                  />
                </div>
              </div>
              <div
                className={`confirm-btn ${
                  openSection === null ? "disabled" : ""
                }`}
              >
                <button
                  type="button"
                  className="elementor-button"
                  disabled={openSection === null}
                  onClick={() => {
                    if (!editing) {
                      setSidebarMap(true);
                      setActiveConfirm(true);  
                    }
                  }}
                >
                  View Code Compliant Products
                </button>
              </div>
            </React.Fragment>
          ) : showSidebarMap ? (
            ""
          ) : (
            <div className="box-right">
              <h6 className="title">Enter a US CITY or ZIP CODE location</h6>
              <div className="city-map-box">
                <div className="map-box">
                  <div className="search-form-wrap energy-cal-location-search">
                    <div className="location_icon">
                      <img src={Images && Images.locationIcon} alt="img" />
                    </div>
                    <input
                      type="text"
                      placeholder="Enter zip code or city name"
                      onChange={handleChange}
                      value={searchTerm}
                      style={{ border: "none" }}
                    />
                    {searchTerm.length <= 0 ? (
                      ""
                    ) : (
                      <div
                        className="input_clear"
                        onClick={() => {
                          setSearchTerm("");
                          setFilteredCities([]);
                        }}
                      >
                        <img src={Images && Images.crossIcon} alt="img" />
                      </div>
                    )}
                  </div>
                  <div
                    className={`map-img-trans ${
                      activeButton !== "city"
                        ? `${
                            editing || searchTerm <= 0
                              ? ""
                              : searchTerm <= 0
                              ? "active"
                              : ""
                          }`
                        : ""
                    }`}
                  >
                    <div className="map-img-wrap">
                      <img src={Images.mapImg} alt="img" />
                    </div>
                  </div>
                  <div
                    className={`location-dropdown ${
                      activeButton === "city" &&
                      filteredCities?.length !== 0 &&
                      editing
                        ? "active"
                        : ""
                    }`}
                  >
                    {filteredCities?.length > 0 ? (
                      <ul>
                        {filteredCities.map((item, index) => (
                          <li key={index} onClick={() => getZipcode(item)}>
                            <span className="direction state">
                              {item.state}
                            </span>
                            <h4 className="location city-loc">{item.city}</h4>
                          </li>
                        ))}
                      </ul>
                    ) : isSearched ? (
                      <p style={{ textAlign: "center" }}>No records found.</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`discover-btn ${zipcode === "" ? "disable" : ""}`}
              >
                <Link
                  to={
                    zipcode === ""
                      ? "#"
                      : `${basePath}${pagePath}/?zipcode=${zipcode}`
                  }
                  className={`discover ${zipcode === "" ? "disabled" : ""}`}
                  onClick={(e) => {
                    if (zipcode === "") {
                      e.preventDefault();
                    } else {
                      setEditing(false);
                      setZipCodes("");
                    }
                  }}
                >
                  Choose This Location
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="energy-location-right">
          <div className={`help-popup ${Ishelp ? "slide-left" : ""}`}>
            <div className="help-icon" onClick={handleHelpButton}>
              <img src={Images.HelpIconPopup} width={24} height={24} alt="" />
            </div>
            <div className="help-popup-inner">
              <div className="box-left" onClick={handleHelpButton}>
                <img
                  src={
                    ActiveConfirm && activeText === "Energy"
                      ? Images.tabIconPopup
                      : activeText === "Comfort"
                      ? Images.WinIconPopup
                      : !ActiveConfirm
                      ? Images.CodeIconPopup
                      : ""
                  }
                  width={74}
                  height={52}
                  alt=""
                />
              </div>
              <div className="box-rignt">
                <span className="tips">tips</span>
                <p className="tips-description">
                  {ActiveConfirm && activeText === "Energy" ? (
                    <React.Fragment>
                      Tap on the Glass
                      <br /> packages to get
                      <br /> more detail{" "}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {activeText === "Comfort" ? (
                    <React.Fragment>
                      Change window
                      <br /> configuration to get
                      <br /> suitable result
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {!ActiveConfirm ? (
                    <React.Fragment>
                      Choose a code <br /> compliance
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>
          <div id="energy-map"></div>
          <div
            className={`map-hide ${ActiveListDetails ? "active" : ""}`}
            onClick={handleCloseMap}
          >
            <img src={Images.closeModalIcon} attr="img" />
          </div>
          <div
            className={`comfort-wrap ${
              activeText === "Comfort" ? "active" : ""
            }`}
          >
            <div
              className={`energy-cal-location popup-energy-graph comfortAnalysis ${
                isPortrait ? "setportrait" : ""
              } ${ActiveConfirm ? "slide-right" : ""} ${
                isLandscape ? "landscapeview" : ""
              }`}
              style={{ padding: "0px 0px !important" }}
              id="popup-energy-graph"
            >
              <div className="mobile-chart-header">
                <div className="back_arrow" onClick={chartBackButton}>
                  <img
                    src={Images && Images.toggleIconSidebar}
                    alt="img"
                    className="back_arrow_img"
                  />
                </div>
                <div className="mobile-location_block">
                  <img src={Images && Images.locationIcon} alt="img" />
                  <h3>
                    {" "}
                    {locationData.primary_city},{" "}
                    <strong>{locationData.state_id}</strong>
                  </h3>
                </div>
              </div>
              {/*locationwith icon */}
              <div
                className={`energy-location-wrappers comfort-location-title ${
                  isPortrait ? "setportrait" : ""
                } ${ActiveConfirm ? "slide-right" : ""}`}
              >
                <div className="energy-location-comfort">
                  <img src={Images && Images.locationIcon} alt="img" />
                  <span>
                    {" "}
                    {locationData.primary_city},{" "}
                    <strong>{locationData.state_id}</strong>
                  </span>
                </div>

                <div
                  className={`emergy-title-location-wrap ${
                    activeclass ? "active" : ""
                  }`}
                >
                  <div className="chart-buttton-top">
                    <button
                      type="button"
                      className={`button energy_savings ${
                        activeText === "Energy" ? "active" : ""
                      }`}
                      onClick={() => handleChartBtnClick("Energy")}
                    >
                      Energy Savings
                    </button>
                    <button
                      type="button"
                      onClick={() => viewComfort("Comfort")}
                      className={`button comfort_analysis ${
                        activeText === "Comfort" ? "active" : ""
                      }`}
                      disabled={activeText === "Comfort"}
                    >
                      Comfort Analysis
                    </button>
                  </div>
                </div>
              </div>
              {view3d && (
                <CalculatorScene
                  handleChartBtnClick={handleChartBtnClick}
                  viewComfort={viewComfort}
                  activeclass={activeclass}
                  comfortdata={comfortdata}
                  ActiveConfirm={ActiveConfirm}
                  chartData={chartData}
                  setComfortData={setComfortData}
                  openSection={openSection}
                  isPrescriptiveChecked={isPrescriptiveChecked}
                  locationData={locationData}
                  handleSidebarToggle={handleSidebarToggle}
                  energyComplianceData={energyComplianceData}
                  isMobile={isMobile}
                  setIsFourK={setIsFourK}
                  IsFourK={IsFourK}
                  visiblePoup={visiblePoup}
                  setVisiblePoup={setVisiblePoup}
                  handleexclamationClick={handleexclamationClick}
                  handleCloseDiscPopup={handleCloseDiscPopup}
                  complianceData={complianceData}
                  hoveredBarPosition={hoveredBarPosition}
                  isLandscape={isLandscape}
                  isLoaded={isLoaded}
                  setIsLoaded={setIsLoaded}
                  containerRef={containerRef}
                  cardinalCalSceneInstance={cardinalCalSceneInstance}
                  setCardinalCalSceneInstance={setCardinalCalSceneInstance}
                />
              )}
            </div>
          </div>

          <div
            className={`energy-chart-wrap ${
              activeText !== "Comfort" ? "active" : ""
            }`}
          >
            <div
              className={`energy-cal-location popup-energy-graph ${
                isPopupCentred ? "popup-centerd" : ""
              } ${ActiveConfirm ? "slide-right" : ""}`}
              id="popup-energy-graph"
            >
              {/*locationwith icon */}
              <div
                className={`energy-location-wrappers  ${
                  isPortrait ? "setportrait" : ""
                } ${ActiveConfirm ? "slide-right" : ""}`}
              >
                <div className="energy-location-comfort">
                  <img src={Images && Images.locationIcon} alt="img" />
                  <span>
                    {" "}
                    {locationData.primary_city},{" "}
                    <strong>{locationData.state_id}</strong>
                  </span>
                </div>
                <div
                  className={`emergy-title-location-wrap ${
                    activeclass ? "active" : ""
                  }`}
                >
                  <div className="chart-buttton-top">
                    <button
                      type="button"
                      className={`button energy_savings ${
                        activeText === "Energy" ? "active" : ""
                      }`}
                      onClick={() => handleChartBtnClick("Energy")}
                    >
                      Energy Savings
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        viewComfort("Comfort");
                      }}
                      className={`button comfort_analysis ${
                        activeText === "Comfort" ? "active" : ""
                      }`}
                      disabled={activeText === "Comfort"}
                    >
                      Comfort Analysis
                    </button>
                  </div>
                </div>
              </div>
              <div className="popup-energy-graph-block">
                <div className="mobile-chart-header">
                  <div className="back_arrow" onClick={chartBackButton}>
                    <img
                      src={Images && Images.toggleIconSidebar}
                      alt="img"
                      className="back_arrow_img"
                    />
                  </div>
                  <div className="mobile-location_block">
                    <img src={Images && Images.locationIcon} alt="img" />
                    <h3>
                      {" "}
                      {locationData.primary_city},{" "}
                      <strong>{locationData.state_id}</strong>
                    </h3>
                  </div>
                </div>
                <div className="char-wrap-main-block">
                  <div className="products-header-char-wrap">
                    <div className="compliant-products-header">
                      <h4 className="title">
                        Compliant with{" "}
                        {openSection !== 0
                          ? `${
                              locationData.IECC21 === "4" &&
                              locationData.Moisture21 === "C"
                                ? "IECC 2021 Zone 5"
                                : locationData.IECC21 === "4"
                                ? "IECC 2021 Zone 4 Marine"
                                : `IECC 2021 Zone ${locationData.iecc21_moisture21}`
                            }`
                          : `ENERGY STAR 7 ${locationData.energy_star_zone} Zone`}
                        {openSection !== 0 ? (
                          <div
                            className="exclamationIcon-show-popup"
                            onClick={() => handleexclamationClick(2)}
                          >
                            <img
                              src={Images.exclamationIcon2}
                              width={20}
                              height={20}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {/*disclimer popup */}
                        <div
                          className={`ncomfortable-popup ${
                            visiblePoup === 2 ? "show" : ""
                          }`}
                        >
                          <div className="ncomfortable-popup-inner">
                            <div
                              className="popup-close"
                              onClick={handleCloseDiscPopup}
                            >
                              <span className="close-icon">
                                <img
                                  src={Images.crossIcon}
                                  width={10}
                                  height={10}
                                  alt=""
                                />
                              </span>
                            </div>
                            <h6>
                              This application provides guidance based on the
                              2021 International Energy Conservation Code (IECC)
                              for evaluating window and door configurations.
                            </h6>
                            <p>
                              Local building codes may differ from the IECC 2021
                              standards and can vary by state or municipality.
                              For precise compliance details, consult your local
                              code enforcement office. Use this tool for
                              preliminary guidance only, not as a substitute for
                              professional advice.
                            </p>
                          </div>
                          <img
                            src={Images && Images.caretDown}
                            width={20}
                            height={20}
                            alt=""
                            className="caret-down"
                          />
                        </div>
                      </h4>

                      <div className="checkbox-custom reactangle-checkbox desktop-non">
                        <label className="elementor-button">
                          <input
                            type="checkbox"
                            checked={showNonCompliant}
                            onChange={() => {
                              setShowNonCompliant(!showNonCompliant),
                                setHoveredBarPosition(null);
                              handleCloseDiscPopup();
                            }}
                          />

                          <span className="checkmark">
                            Show Non-Compliant Products
                          </span>
                        </label>
                      </div>
                    </div>
                    <p className="lanscapeview-subheading-chart">
                      Tap on the Glass packages to get more detail
                    </p>
                    <div className="grap-block">
                      <div className="grap-block-inner">
                        <ChartComponent
                          chartRef={chartRef}
                          ActiveConfirm={ActiveConfirm}
                          chartData={chartData}
                          showNonCompliant={showNonCompliant}
                          viewComfort={viewComfort}
                          isMobile={isMobile}
                          isMac={isMac}
                          setIsmac={setIsmac}
                          setIsmobile={setIsmobile}
                          openSection={openSection}
                          isPrescriptiveChecked={isPrescriptiveChecked}
                          hoveredBarPosition={hoveredBarPosition}
                          setHoveredBarPosition={setHoveredBarPosition}
                          locationData={locationData}
                          setIsFourK={setIsFourK}
                          IsFourK={IsFourK}
                          visiblePoup={visiblePoup}
                          handleexclamationClick={handleexclamationClick}
                          handleCloseDiscPopup={handleCloseDiscPopup}
                          isPortrait={isPortrait}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="chart-type-list-wrap-main">
                    <div className="chart-type-list">
                      <div className="checkbox-custom reactangle-checkbox mobile-non-compliant">
                        <label className="elementor-button">
                          <input
                            type="checkbox"
                            checked={showNonCompliant}
                            onChange={() => {
                              setShowNonCompliant(!showNonCompliant);
                              setHoveredBarPosition(null);
                            }}
                          />
                          <div className="checkbox-mobile-chart">
                            <img
                              src={Images.checkbox_icon_rect_blank}
                              alt="img"
                              className="check-blank-rect"
                            />
                            <img
                              src={Images.checkbox_icon_rect_fill}
                              alt="img"
                              className="check-fill-rect"
                            />
                          </div>
                          <span className="checkmark">
                            Show Non-Compliant Products
                          </span>
                        </label>
                      </div>
                      <div className="chart-type-list-wrap">
                        <ul className="checkbox-custom reactangle-checkbox">
                          {showNonCompliant ? (
                            <li>
                              <label className="non-compliant">
                                <span className="checkmark">
                                  Non-Compliant Products
                                </span>
                              </label>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            <label className="compliant">
                              <span className="checkmark">
                                {openSection !== 0
                                  ? "Compliant Products"
                                  : complianceData?.energy_equivalent_path &&
                                    complianceData?.energy_equivalent_path
                                  ? "Prescriptive Path"
                                  : "Compliant Products"}
                              </span>
                            </label>
                          </li>
                          {openSection !== 1 &&
                          complianceData?.energy_equivalent_path &&
                          complianceData?.energy_equivalent_path ? (
                            <li>
                              <label className="compliant">
                                <span className="checkmark checkmark-equivalent">
                                  Energy Equivalent Path
                                </span>
                              </label>
                            </li>
                          ) : (
                            ""
                          )}

                          <li className="about-energy-savings">
                            <span onClick={() => handleexclamationClick(3)}>
                              * About Energy Savings
                            </span>

                            <div
                              className={`ncomfortable-popup about-energy-savings-popup ${
                                visiblePoup === 3 ? "show" : ""
                              }`}
                            >
                              <div className="ncomfortable-popup-inner">
                                <div className="popup-close">
                                  <span
                                    className="close-icon"
                                    onClick={handleCloseDiscPopup}
                                  >
                                    <img
                                      src={Images.crossIcon}
                                      width={10}
                                      height={10}
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <h6></h6>
                                <p>
                                  Please note that the estimated energy savings
                                  provided are based on general assumptions and
                                  average conditions. Actual savings may vary
                                  based on specific home characteristics, such
                                  as the home’s design, construction materials,
                                  insulation levels, condition and efficiency of
                                  HVAC systems, and personal energy usage
                                  preferences. We advise consulting with a
                                  professional to assess potential savings
                                  specific to your home environment.
                                </p>
                              </div>
                              <img
                                src={Images && Images.caretDown}
                                width={20}
                                height={20}
                                alt=""
                                className="caret-down"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exclamation-mark">
                  <img src={Images && Images.questionIcon} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <React.Fragment>
            <div
              className="modal-backdrop"
              onClick={() => {
                setIsModalOpen(false);
                body.style.overflow = "auto";
              }}
            ></div>
            <div
              className={`energy-cal-location popup-download-graph popup-main-block ${
                isModalOpen ? "show-popup" : ""
              }`}
            >
              <div className={`elementor-column-gap-default`}>
                <div className="popup-inner">
                  <div className="energy-close-popup">
                    <button
                      type="button"
                      className="elementor-button"
                      onClick={() => handleModalClose()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                      >
                        <g
                          id="Group_4780"
                          data-name="Group 4780"
                          transform="translate(-182 -84)"
                        >
                          <circle
                            id="Ellipse_160"
                            data-name="Ellipse 160"
                            cx="13"
                            cy="13"
                            r="13"
                            transform="translate(182 84)"
                            fill="#fff"
                          ></circle>
                          <g
                            id="Group_4779"
                            data-name="Group 4779"
                            transform="translate(-3.738 -1.969)"
                          >
                            <path
                              id="Path_3665"
                              data-name="Path 3665"
                              d="M9875.206,14655.729l8.477,8.477"
                              transform="translate(-9680.706 -14561)"
                              fill="none"
                              stroke="#000"
                              stroke-width="1"
                            ></path>
                            <path
                              id="Path_3666"
                              data-name="Path 3666"
                              d="M0,0,8.478,8.477"
                              transform="translate(194.5 103.207) rotate(-90)"
                              fill="none"
                              stroke="#000"
                              stroke-width="1"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div className="emergy-title-location-wrap">
                    <EnergyLogo />
                  </div>
                  <div className="share-download-wrap">
                    <p className="loc-title-graph">
                      <strong>
                        {locationData && locationData.primary_city}
                        {", "}
                        {locationData && locationData.state} –
                      </strong>{" "}
                      {locationData && locationData.zip_code}
                    </p>
                  </div>
                  <div className="grap-block">
                    <div className="grap-block-inner">
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="Chart Preview"
                          className="preview_chart"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="energy-star-block">
                    <p className="star-description">
                      <strong>Note:</strong> Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod.
                    </p>
                    <div className="energy-star-com">
                      <img
                        src={Images && Images.starIcon}
                        alt="img"
                        className="star-img"
                      />
                      <h6 className="title">ENERGY STAR COMPLIANT</h6>
                      <div className="exclamation-mark">
                        <img src={Images && Images.exclamationIcon} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default EnergyLocation;
