import React from "react";
import Images from "../../images";
const EnergyLogo = (props) => {
  const { customStyle } = props;
  return (
    <>
      <div
        className={`emergy-cal-title ${customStyle}`}
        style={{
          backgroundImage: `url(${Images.energyComfortLogo})`,
        }}
      >
        <h3 className="elementor-icon-box-title">
          <span>
            <strong>
              Energy+
              <br /> Comfort
              <br />{" "}
            </strong>{" "}
            Calculator{" "}
          </span>
        </h3>
      </div>
      <div className="loeasy-logo">
        <img src={Images.logo_loeasy} alt="" className="portrait-icon" />
      </div>
    </>
  );
};
export default EnergyLogo;
