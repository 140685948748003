const isProduction = process.env.REACT_APP_NODE_ENV === "production";
const baseURL = isProduction
  ? `${window.wp_config.wp_template_directory_uri}/dist-development/img/`
  : `${window.wp_config.wp_template_directory_uri}/src/images/`;

const listIcon = baseURL + "list-icon.png";
const backArrow = baseURL + "gray-back-arrow.svg";
const locationIcon = baseURL + "location-icon.svg";
const gasIcon = baseURL + "gas-icon.svg";
const electriceIcon = baseURL + "electrice.svg";
const downloadIcon = baseURL + "download-icon.svg";
const exclamationIcon = baseURL + "exclamation-mark.png";
const unionIcon = baseURL + "Union.svg";
const starImg = baseURL + "star-icon.svg";
const leftLine = baseURL + "left-line-img.svg";
const rightLine = baseURL + "right-line-img.svg";
const mapIcon = baseURL + "map-icon.png";
const markerIcon = baseURL + "marker.png";
const toggleIconSidebar = baseURL + "toggle-icon-sidebar.svg";
const backArrowIcon = baseURL + "back-arrow.svg";
const mapImg = baseURL + "map-img.png";
const crossIcon = baseURL + "cross-icon.png";
const questionIcon = baseURL + "question-icon.svg";
const closeModalIcon = baseURL + "close-modal.png";
const checkIcon = baseURL + "check-icon.svg";
const DotToggle = baseURL + "three-dot-toggle.svg";
const Logo_272 = baseURL + "logo-loe272.png";
const Logo_270 = baseURL + "logo-loe270.png";
const Logo_180 = baseURL + "logo-loe180.png";
const Logo_240 = baseURL + "logo-loe240.png";
const Logo_340 = baseURL + "logo-loe340.png";
const Logo_366 = baseURL + "logo-loe366.png";
const Logo_quad = baseURL + "logo-quad.png";
const logo_loe_240 = baseURL + "loe-240.png";
const pane_3 = baseURL + "3pane.png";
const panecoat = baseURL + "3panecoat.png";
const paneloe = baseURL + "3paneloe.png";
const crdLogo = baseURL + "logo.png";
const tutorgif = baseURL + "tutor.gif";
const vertical5Img = baseURL + "vertical-icon4.svg";
const compliantIcon = baseURL + "compliant-icon.svg";
const summerIcon = baseURL + "summer-icon.svg";
const winterNightIcon = baseURL + "winter-night-icon.svg";
const womanImageComfort = baseURL + "woman-image-comfort.png";
const womanImageUnComfort = baseURL + "woman-image-uncomfort.png";
const blindProgress = baseURL + "blind-progress-img.svg";
const horizontalLine = baseURL + "horizontal-line.svg";
const horizontalLineIcon = baseURL + "horizontal-line-icon.svg";
const horizontalLineIcon2 = baseURL + "horizontal-line-icon2.svg";
const horizontalLineIconWoman = baseURL + "woman-icon-horizontal-line.svg";
const horizontalLineIconWomanNew =
  baseURL + "woman-icon-horizontal-line-new.svg";
const comfortableIcon = baseURL + "comfortable-icon.svg";
const progressIcon = baseURL + "progress-rotate-icon.svg";
const notCompliantIcon = baseURL + "not-compliant-icon.svg";
const exclamationIconCompliant = baseURL + "exclamation-icon-compliant.svg";

const winterIconNight = baseURL + "winter-icon-night.svg";
const blackWomanIcon = baseURL + "black-woman-icon.svg";
const womanveryunconfort = baseURL + "woman-very-unconfor-icon.svg";
const womanfairlyicon = baseURL + "woman-fairly-icon.svg";
const womancomfortable = baseURL + "woman-comfortable-icon.svg";
const happyFaceWinter = baseURL + "happyfacehorizontally.svg";
const caretDown = baseURL + "caret-down.svg";
const exclamationIcon2 = baseURL + "exclamation-icon-2.svg";
const energyComfortLogo = baseURL + "energy-comfort-logo.svg";
const tabIconPopup = baseURL + "tab-icon-popup.svg";
const HelpIconPopup = baseURL + "help-icon-popup.svg";
const CodeIconPopup = baseURL + "tipcodeimg.svg";
const WinIconPopup = baseURL + "tipswindowconfig.svg";
const shildLast = baseURL + "shild-last-index.png";
const energycalbg = baseURL + "energy-cal-background-img.jpg";
const phone_rotate_icon = baseURL + "phone-rotate-icon.png";
const landscape_rotate_icon = baseURL + "landscape-rotate-icon.png";
const loader_gif = baseURL + "loader.gif";
const primary_coating_shild = baseURL + "primary-coating-shild.png";
const logo_loeasy = baseURL + "logo-loeasy.png";
const nudge_flip_banner_vertical = baseURL + "nudge-flip-phone-horizontally.jpg";
const screen_rotate_img = baseURL + "screen-rotate-img.png";
const checkbox_icon_rect_blank = baseURL + "checkbox-icon-rect-blank.png";
const checkbox_icon_rect_fill = baseURL + "checkbox-icon-rect.png";

const Images = {
  summerIcon,
  loader_gif,
  checkbox_icon_rect_fill,
  checkbox_icon_rect_blank,
  logo_loeasy,
  screen_rotate_img,
  primary_coating_shild,
  phone_rotate_icon,
  nudge_flip_banner_vertical,
  landscape_rotate_icon,
  shildLast,
  caretDown,
  exclamationIcon2,
  womanImageComfort,
  womanImageUnComfort,
  womanveryunconfort,
  womanfairlyicon,
  WinIconPopup,
  CodeIconPopup,
  womancomfortable,
  blackWomanIcon,
  blindProgress,
  progressIcon,
  happyFaceWinter,
  notCompliantIcon,
  comfortableIcon,
  exclamationIconCompliant,
  horizontalLineIconWoman,
  horizontalLineIconWomanNew,
  horizontalLineIcon2,
  energyComfortLogo,
  horizontalLineIcon,
  horizontalLine,
  winterNightIcon,
  listIcon,
  tabIconPopup,
  backArrow,
  HelpIconPopup,
  locationIcon,
  gasIcon,
  electriceIcon,
  downloadIcon,
  exclamationIcon,
  unionIcon,
  questionIcon,
  starImg,
  leftLine,
  rightLine,
  mapIcon,
  markerIcon,
  toggleIconSidebar,
  backArrowIcon,
  mapImg,
  crossIcon,
  closeModalIcon,
  checkIcon,
  vertical5Img,
  DotToggle,
  Logo_272,
  Logo_270,
  Logo_180,
  Logo_240,
  Logo_340,
  Logo_366,
  Logo_quad,
  pane_3,
  panecoat,
  paneloe,
  crdLogo,
  energycalbg,
  tutorgif,
  compliantIcon,
  winterIconNight,
  logo_loe_240,
};

export default Images;
