import React, { useEffect, useRef, useState } from "react";
import "./energychart.css";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartBarPopup from "../ChartBarPopup";
import Images from "../../images";

const ChartComponent = ({
  ActiveConfirm,
  chartData,
  showNonCompliant,
  viewComfort,
  isMobile,
  openSection,
  isPrescriptiveChecked,
  hoveredBarPosition,
  setHoveredBarPosition,
  isMac,
  setIsmac,
  locationData,
  setIsFourK,
  IsFourK,
  handleCloseDiscPopup,
  handleexclamationClick,
  visiblePoup,
  setIsmobile,
  activeText,
}) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  let text = [];

  useEffect(() => {
    window.scrollTo(0, 0);
    function macResponsiveCheck() {
      if (window.innerWidth <= 1600) {
        setIsmac(true);
      }
    }
    function fourKResponsiveCheck() {
      if (window.innerWidth >= 3100) {
        setIsFourK(true);
      }
    }
    function mobileResponsiveCheck() {
      if (window.innerWidth <= 768) {
        setIsmobile(true);
      }else{
        setIsmobile(false)
      }
    }

    macResponsiveCheck();
    fourKResponsiveCheck();
    mobileResponsiveCheck();
    window.addEventListener("resize", macResponsiveCheck);
    window.addEventListener("resize", fourKResponsiveCheck);
    window.addEventListener("resize", mobileResponsiveCheck);

    return () => {
      window.removeEventListener("resize", macResponsiveCheck);
      window.removeEventListener("resize", fourKResponsiveCheck);
      window.removeEventListener("resize", CheckWindowOrientation);
      window.removeEventListener("resize", mobileResponsiveCheck);
    };
  }, [isMac, IsFourK, isMobile]);

  const [isPortrait, setIsPortrait] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    CheckWindowOrientation();
    window.addEventListener("resize", CheckWindowOrientation);
    return () => {
      window.removeEventListener("resize", CheckWindowOrientation);
    };
  }, [isPortrait]);

  function CheckWindowOrientation() {
    let isLandscape = window?.screen?.orientation?.type === "landscape-primary";
    if (
      window.innerWidth < 767 &&
      window.innerWidth < window.innerHeight &&
      !isLandscape
    ) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
  }
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const compliantProducts = [];
    const compliantProductsColors = [];
    const noncompliantProductsColors = [];
    const noncompliantProducts = [];
    const isCompliance = [];

    if (openSection !== 0) {
      if (chartData && chartData.HSLE && chartData.MSLE && chartData.LSLE) {
        const categoryKeys = Object.keys(chartData);
        const dataLength = chartData && chartData[categoryKeys[0]].length;
        for (let i = 0; i < dataLength; i++) {
          const hsleData = chartData.HSLE[i];
          const msleData = chartData.MSLE[i];
          const lsleData = chartData.LSLE[i];
          compliantProducts.push({
            hsle: hsleData.compliance === "Yes" ? hsleData.value : "",
            msle: msleData.compliance === "Yes" ? msleData.value : "",
            lsle: lsleData.compliance === "Yes" ? lsleData.value : "",
          });
          if (hsleData.compliance === "Yes") {
            compliantProductsColors.push(hsleData.color_code.normal);
          }
          if (msleData.compliance === "Yes") {
            compliantProductsColors.push(msleData.color_code.normal);
          }
          if (lsleData.compliance === "Yes") {
            compliantProductsColors.push(lsleData.color_code.normal);
          }
        }
      }
    } else {
      if (isPrescriptiveChecked) {
        if (chartData && chartData.HSLE && chartData.MSLE && chartData.LSLE) {
          const categoryKeys = Object.keys(chartData);
          const dataLength = chartData && chartData[categoryKeys[0]].length;
          for (let i = 0; i < dataLength; i++) {
            const hsleData = chartData.HSLE[i];
            const msleData = chartData.MSLE[i];
            const lsleData = chartData.LSLE[i];
            compliantProducts.push({
              hsle:
                hsleData.nothern_compliance.prescriptive_path === "Yes" ||
                  hsleData.nothern_compliance.energy_equivalent_path === "Yes"
                  ? hsleData.value
                  : "",
              msle:
                msleData.nothern_compliance.prescriptive_path === "Yes" ||
                  msleData.nothern_compliance.energy_equivalent_path === "Yes"
                  ? msleData.value
                  : "",
              lsle:
                lsleData.nothern_compliance.prescriptive_path === "Yes" ||
                  lsleData.nothern_compliance.energy_equivalent_path === "Yes"
                  ? lsleData.value
                  : "",
            });
            if (
              hsleData.nothern_compliance.prescriptive_path === "Yes" ||
              hsleData.nothern_compliance.energy_equivalent_path === "Yes"
            ) {
              compliantProductsColors.push(
                hsleData.nothern_compliance.energy_equivalent_path === "Yes"
                  ? hsleData.color_code.equivalent_path
                  : hsleData.color_code.prescriptive_path
              );
            }
            if (
              msleData.nothern_compliance.prescriptive_path === "Yes" ||
              msleData.nothern_compliance.energy_equivalent_path === "Yes"
            ) {
              compliantProductsColors.push(
                msleData.nothern_compliance.energy_equivalent_path === "Yes"
                  ? msleData.color_code.equivalent_path
                  : msleData.color_code.prescriptive_path
              );
            }
            if (
              lsleData.nothern_compliance.prescriptive_path === "Yes" ||
              lsleData.nothern_compliance.energy_equivalent_path === "Yes"
            ) {
              compliantProductsColors.push(
                lsleData.nothern_compliance.energy_equivalent_path === "Yes"
                  ? lsleData.color_code.equivalent_path
                  : lsleData.color_code.prescriptive_path
              );
            }
          }
        }
      }
    }
    if (chartData && chartData.HSLE && chartData.MSLE && chartData.LSLE) {
      const minLength = Math.min(
        chartData.HSLE.length,
        chartData.MSLE.length,
        chartData.LSLE.length
      );

      for (let i = 0; i < minLength; i++) {
        noncompliantProducts.push({
          hsle: chartData.HSLE[i].value,
          msle: chartData.MSLE[i].value,
          lsle: chartData.LSLE[i].value,
        });

        if (openSection !== 0) {
          //iecc
          isCompliance.push({
            hsleisCompliance:
              chartData.HSLE[i].compliance === "Yes" ? true : false,
            msleisCompliance:
              chartData.MSLE[i].compliance === "Yes" ? true : false,
            lsleisCompliance:
              chartData.LSLE[i].compliance === "Yes" ? true : false,
          });
        } else {
          if (
            isPrescriptiveChecked &&
            locationData.energy_star_zone === "Northern"
          ) {
            //energy star
            isCompliance.push({
              hsleisCompliance:
                chartData.HSLE[i].nothern_compliance.prescriptive_path ===
                  "Yes" ||
                  chartData.HSLE[i].nothern_compliance.energy_equivalent_path ===
                  "Yes"
                  ? chartData.HSLE[i].nothern_compliance.prescriptive_path ===
                    "Yes"
                    ? true
                    : false
                  : false,
              msleisCompliance:
                chartData.MSLE[i].nothern_compliance.prescriptive_path ===
                  "Yes" ||
                  chartData.MSLE[i].nothern_compliance.energy_equivalent_path ===
                  "Yes"
                  ? chartData.HSLE[i].nothern_compliance.prescriptive_path ===
                    "Yes"
                    ? true
                    : false
                  : false,
              lsleisCompliance:
                chartData.LSLE[i].nothern_compliance.prescriptive_path ===
                  "Yes" ||
                  chartData.LSLE[i].nothern_compliance.energy_equivalent_path ===
                  "Yes"
                  ? chartData.HSLE[i].nothern_compliance.prescriptive_path ===
                    "Yes"
                    ? true
                    : false
                  : false,
            });
          } else {
            isCompliance.push({
              hsleisCompliance:
                chartData.HSLE[i].nothern_compliance.prescriptive_path ===
                  "Yes" ||
                  chartData.HSLE[i].nothern_compliance.energy_equivalent_path ===
                  "Yes"
                  ? true
                  : false,
              msleisCompliance:
                chartData.MSLE[i].nothern_compliance.prescriptive_path ===
                  "Yes" ||
                  chartData.MSLE[i].nothern_compliance.energy_equivalent_path ===
                  "Yes"
                  ? true
                  : false,
              lsleisCompliance:
                chartData.LSLE[i].nothern_compliance.prescriptive_path ===
                  "Yes" ||
                  chartData.LSLE[i].nothern_compliance.energy_equivalent_path ===
                  "Yes"
                  ? true
                  : false,
            });
          }
        }

        noncompliantProductsColors.push({
          hsleclr: getCorrectColor(
            chartData.HSLE[i],
            isPrescriptiveChecked,
            openSection
          ),

          msleclr: getCorrectColor(
            chartData.MSLE[i],
            isPrescriptiveChecked,
            openSection
          ),
          lsleclr: getCorrectColor(
            chartData.LSLE[i],
            isPrescriptiveChecked,
            openSection
          ),
        });
      }

      chartData.HSLE.slice(minLength).forEach((item, index) => {
        noncompliantProducts.push({
          hsle: item ? item.value : "",
          msle: "",
          lsle: "",
        });

        noncompliantProductsColors.push({
          hsleclr: item
            ? getCorrectColor(item, isPrescriptiveChecked, openSection)
            : "",
          msleclr: "",
          lsleclr: "",
        });
      });
    }
    for (let key in chartData) {
      if (chartData.hasOwnProperty(key)) {
        chartData[key].forEach((item) => {
          text.push(item.product_type);
        });
      }
    }
    function getCorrectColor(data, isPrescriptiveChecked, openSection) {
      if (data) {
        if (openSection !== 0) {
          return data.color_code.normal;
        } else {
          if (isPrescriptiveChecked) {
            return data.nothern_compliance.prescriptive_path === "Yes"
              ? data.color_code.prescriptive_path
              : data.color_code.equivalent_path || data.color_code.normal;
          } else {
            return data.color_code.equivalent_path;
          }
        }
      }
      return "";
    }
    const data = showNonCompliant ? noncompliantProducts : compliantProducts;

    const maxBarValue = Math.max(
      ...data.flatMap((product) => [product.hsle, product.msle, product.lsle])
    );
    let maxDisplayValue;
    if (maxBarValue && maxBarValue) {
      maxDisplayValue =
        maxBarValue % 2 === 0 ? maxBarValue + 2 : maxBarValue + 3;
    }
    if (!maxDisplayValue) {
      maxDisplayValue = 40;
    }
    const backgroundColors = showNonCompliant
      ? noncompliantProductsColors
      : compliantProductsColors;
    let datasets = [];
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: ActiveConfirm ? true : false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              label += Math.round(context.parsed.y) + "%";
              return label;
            },
            afterTitle: function (context) {
              let producttype = text[context[0].datasetIndex];
              return producttype;
            },
          },
        },
        datalabels: {
          anchor: "start",
          align: "end",
          formatter: (value, context) => {
            let producttype = value ? text[context.datasetIndex] : "";
            if (value && value > 0 && value < 9 && !isMobile && !IsFourK) {
              producttype = text[context.datasetIndex].slice(0, 6) + " ...";
            }
            if (value && value > 0 && value <= 7 && isMobile) {
              producttype = text[context.datasetIndex].slice(0, 6) + " ...";
            }
            return [producttype];
          },
          color: function (context) {
            let clrcode =
              context.dataset.isCompliances[context.dataIndex] == true
                ? "#000"
                : "#fff";
            return clrcode;
          },
          clamp: true,
          font: {
            size: showNonCompliant
              ? IsFourK
                ? "24px"
                : (isMobile && isPortrait === false) || isMobile
                  ? "7px"
                  : "16px"
              : IsFourK
                ? "24px"
                : (isMobile && isPortrait === false) || isMobile
                  ? "7px"
                  : "16px",
          },
          rotation: -90,
          offset: 15,
        },
      },
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          max: maxDisplayValue,
          ticks: {
            stepSize: 2,
            callback: function (value) {
              if (value === 0 || value === maxDisplayValue || value % 2 === 0) {
                return value + "%";
              } else {
                return "";
              }
            },
            color: "#ffffff80",
          },
          grid: {
            color: "#ffffff1a",
            padding: 15,
          },
          border: {
            display: false,
          },
        },
      },
    };
    if (chartInstance) {
      chartInstance.destroy();
    }

    if (!showNonCompliant) {
      data.forEach((product, index) => {
        datasets.push({
          label: "",
          data: [product.hsle, product.msle, product.lsle],
          backgroundColor: backgroundColors[index % backgroundColors.length],
          hoverBorderColor: "#FFFFFF",
          hoverBorderWidth: 2,
          padding: 20,
          isCompliances: [
            isCompliance[index].hsleisCompliance,
            isCompliance[index].msleisCompliance,
            isCompliance[index].lsleisCompliance,
          ],
        });
      });
    } else {
      data.forEach((product, index) => {
        datasets.push({
          label: "",
          data: [product.hsle, product.msle, product.lsle],
          hoverBorderColor: "#FFFFFF",
          backgroundColor: [
            backgroundColors[index].hsleclr,
            backgroundColors[index].msleclr,
            backgroundColors[index].lsleclr,
          ],
          isCompliances: [
            isCompliance[index].hsleisCompliance,
            isCompliance[index].msleisCompliance,
            isCompliance[index].lsleisCompliance,
          ],
          hoverBorderWidth: 2,
          padding: 20,
        });
      });
    }

    const newChartInstance = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          isMobile ? "HSLE" : "High Solar Gain LoĒ Products",
          isMobile ? "MSLE" : "Medium Solar Gain LoĒ Products",
          isMobile ? "LSLE" : "Low Solar Gain LoĒ Products",
        ],
        datasets: datasets,
      },
      options: options,
      plugins: [
        ChartDataLabels,
        {
          beforeDraw: function (chart) {
            var ctx = chart.ctx;
            ctx.textAlign = "center";
            ctx.fillStyle = "black";
            ctx.font = "bold 12px Arial";

            var xAxis = chart.scales.x;

            var groupWidth = xAxis.width / 3;
            var marginLeft = groupWidth / 9;
            var marginRight = marginLeft * 2;

            var labelWidth = groupWidth - marginRight;

            // Group Label 1
            var marginLeft1 = xAxis.left + marginLeft;

            // Group Label 2
            var marginLeft2 = xAxis.left + marginLeft + groupWidth;

            // Group Label 2
            var marginLeft3 = xAxis.left + marginLeft + 2 * groupWidth;

            // Draw background rectangles behind group text
            var groupLabel1 = document.getElementById("group-label-1");
            groupLabel1.style.width = labelWidth + "px";
            groupLabel1.style.marginLeft = marginLeft1 + "px";

            var groupLabel2 = document.getElementById("group-label-2");
            groupLabel2.style.width = labelWidth + "px";
            groupLabel2.style.marginLeft = marginLeft2 + "px";

            var groupLabel3 = document.getElementById("group-label-3");
            groupLabel3.style.width = labelWidth + "px";
            groupLabel3.style.marginLeft = marginLeft3 + "px";
          },
        },
        {
          afterDraw: function (chart) {
            const ctx = chart.ctx;
            ctx.font = isMobile
              ? "bold 10px Arial"
              : IsFourK
                ? "bold 24px Arial"
                : "bold 16px Arial";
            chart.data.datasets.forEach(function (dataset, i) {
              const meta = chart.getDatasetMeta(i);
              if (!meta.hidden) {
                meta.data.forEach(function (element, index) {
                  const bar = dataset.data[index];
                  if (bar !== "") {
                    const dataString = `${bar}%`;
                    const dataX = element.x;
                    const dataY = element.y - 20;
                    ctx.fillStyle = "white";
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.fillText(dataString, dataX, dataY);
                  }
                });
              }
            });
          },
        },
      ],
    });

    setChartInstance(newChartInstance);
    const handleCanvasClick = (event) => {
      handleCloseDiscPopup();
      const activeElement = newChartInstance.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: true },
        false
      )[0];
      if (activeElement) {
        const { x } = activeElement.element.getProps(["x", "y", "base"]);
        let productType = text[activeElement.datasetIndex];
        const barData = {
          label: newChartInstance.data.labels[activeElement.index],
          value:
            newChartInstance.data.datasets[activeElement.datasetIndex].data[
            activeElement.index
            ],
          productType: productType,
          chartData,
          activeIndex: activeElement.index,
        };
        let divLeft = x;
        let lastIndex =
          activeElement.index === newChartInstance.data.labels.length - 1;
        if (lastIndex) {
          const secondLastBarRect = newChartInstance
            .getDatasetMeta(activeElement.datasetIndex)
            .data[activeElement.index - 1].getProps(["x", "y", "base"]);
          let secondLastBarRectposition = IsFourK
            ? secondLastBarRect.x + 528
            : isMac
              ? secondLastBarRect.x - 139
              : secondLastBarRect.x + 48;
          divLeft = secondLastBarRectposition;
        }
        setHoveredBarPosition({ left: divLeft, lastIndex: lastIndex, barData });
      } else {
        setHoveredBarPosition(null);
      }
    };
    chartRef.current.addEventListener("click", handleCanvasClick);

    return () => {
      if (newChartInstance) {
        newChartInstance.destroy();
      }
      chartRef.current?.removeEventListener("click", handleCanvasClick);
    };
  }, [
    ActiveConfirm,
    isMac,
    showNonCompliant,
    activeText,
    isMobile,
    isPortrait,
  ]);

  const handleClosePopup = () => {
    setHoveredBarPosition(null);
  };

  return (
    <div className="chart_wrap">
      <div className="mobile-chart-title" id="percentage-saving">
        <h4 onClick={() => handleexclamationClick(4)}>
          Percentage Savings vs. 2 Pane Clear Glass*
        </h4>

        <div
          className={`ncomfortable-popup about-energy-savings-popup ${visiblePoup === 4 ? "show" : ""
            }`}
        >
          <div className="ncomfortable-popup-inner">
            <div className="popup-close">
              <span className="close-icon" onClick={handleCloseDiscPopup}>
                <img src={Images.crossIcon} width={10} height={10} alt="" />
              </span>
            </div>
            <h6></h6>
            <p>
              Please note that the estimated energy savings provided are based
              on general assumptions and average conditions. Actual savings may
              vary based on specific home characteristics, such as the home’s
              design, construction materials, insulation levels, condition and
              efficiency of HVAC systems, and personal energy usage preferences.
              We advise consulting with a professional to assess potential
              savings specific to your home environment.
            </p>
          </div>
          <img
            src={Images && Images.caretDown}
            width={20}
            height={20}
            alt=""
            className="caret-down"
          />
        </div>
      </div>
      <canvas ref={chartRef} id="energyChart"></canvas>
      <div className="group-lables-box">
        <div id="group-label-1" className="group-label-1 group-lables">
          {(isMobile && isPortrait === false) || isMobile
            ? "HSLE"
            : "High Solar Gain LoĒ Products"}
        </div>
        <div id="group-label-2" className="group-label-1 group-lables">
          {(isMobile && isPortrait === false) || isMobile
            ? "MSLE"
            : "Medium Solar Gain LoĒ Products"}
        </div>
        <div id="group-label-3" className="group-label-1 group-lables">
          {(isMobile && isPortrait === false) || isMobile
            ? "LSLE"
            : "Low Solar Gain LoĒ Products"}
        </div>
      </div>
      {hoveredBarPosition && (
        <ChartBarPopup
          barPosition={hoveredBarPosition}
          barData={hoveredBarPosition.barData}
          onClose={handleClosePopup}
          viewComfort={viewComfort}
          isMobile={isMobile}
          isMac={isMac}
          setIsFourK={setIsFourK}
          IsFourK={IsFourK}
          locationData={locationData}
          openSection={openSection}
          isPortrait={isPortrait}
        />
      )}
    </div>
  );
};

export default ChartComponent;
