import { Routes, Route, useLocation } from "react-router-dom";
import EnergyCalculator from "./pages/energy-calculator/page";
import EnergyLocation from "./pages/energy-calculator-location/page";
import React, { useEffect, useState } from "react";
function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const zipcode = searchParams.get("zipcode");
  const basePath = process.env.REACT_APP_BASE_PATH || "";
  const pagePath = process.env.REACT_APP_PAGE_PATH || "";
  const [isportrait, setIsPortrait] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    if (typeof document !== "undefined") {
      const headerElement = document?.querySelector(
        ".ekit-template-content-header"
      );
      const footerElement = document?.querySelector(
        ".elementor-location-footer"
      );
      if (headerElement && footerElement && zipcode) {
        headerElement.style.display = "none";
        footerElement.style.display = "none";
      }
      return () => {
        if (headerElement) {
          headerElement.style.display = "";
        }
      };
    }
  }, [zipcode]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isportrait, isLandscape]);

  const handleResize = () => {
    let mode = window.innerWidth && window.innerWidth <= 767 ? true : false;
    setIsPortrait(mode);
    setIsLandscape(!mode);
  };

  return (
    <div>
      <Routes>
        <Route
          path={`${basePath}${pagePath}`}
          element={
            zipcode ? (
              <EnergyLocation isLandscape={isLandscape} />
            ) : (
              <EnergyCalculator isLandscape={isLandscape} />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
