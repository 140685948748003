import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/energy-calculator.css";
import EnergyLogo from "../../components/EnergyCalLogo";
import Images from "../../images";

var API_BASE_URI = window.wp_config.api_uri;
let controller, signal;

const EnergyCalculator = ({ isLandscape }) => {
  const [filteredCities, setFilteredCities] = useState();
  const [activeButton, setActiveButton] = useState("zip-code");
  const [zipcode, setZipCodes] = useState();
  const [activeclass, setActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [abortController, setAbortController] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const basePath = process.env.REACT_APP_BASE_PATH || "";
  const pagePath = process.env.REACT_APP_PAGE_PATH || "";
  const energyComfortPageUrl =
    process.env.REACT_APP_ENERGY_COMFORT_PAGE_URL || "";

  async function handleChanges(event) {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredCities([]);
    const currentAbortController = new AbortController();
    setAbortController(currentAbortController);
    const username = "cardinal";
    const password = "cardinal";
    if (value?.trim()) {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      signal = controller.signal;
      fetch(
        `${API_BASE_URI}/wp-json/energy-calculator/v1/get-cities?search=` +
        value,
        {
          signal: signal,
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setIsSearched(true);
          setFilteredCities(response?.items);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    setActiveButton(value ? "city" : "zip-code");
    setActive(false);
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }

  const getZipcode = (item) => {
    setSearchTerm(item?.city + ", " + item?.state_id);
    setZipCodes(item.zip);
    setActive(true);
    setFilteredCities([]);
  };

  return (
    <div>
      <div
        className={`energy-cal-wraps ${isLandscape ? "landscapeview" : "energy-cal-wraps"}`}
        style={{
          backgroundImage: `url(${Images && Images.energycalbg})`,
        }}
      >
        <div className="energy-cal-wraps-block">

          <div
            className={`energy-cal-wraps-inner ${activeButton === "city"
              ? `${activeclass || searchTerm <= 0 ? "" : "active"}`
              : ""
              }`}
          >
            <div className="box-left">
              <EnergyLogo customStyle={"emergy-cal-title-wrap"} />

              <p>
                An app designed to guide users through the process of selecting
                glass configurations that meet energy efficiency standards, save
                on heating and cooling, and help maintain a comfortable home in
                all seasons.
              </p>
              <div className="btn-wrap">
                <a href={`${basePath}${energyComfortPageUrl}`}>
                  About this App
                </a>
              </div>
            </div>
            <div className="box-right">
              <h6 className="title">Enter a US CITY or ZIP CODE location</h6>
              <div className="city-map-box">
                <div className="map-box">
                  <div className="search-form-wrap">
                    <input
                      type="text"
                      placeholder="Enter zip code or city name"
                      onChange={handleChanges}
                      value={searchTerm}
                    />
                    {searchTerm.length <= 0 ? (
                      ""
                    ) : (
                      <div
                        className="input_clear"
                        onClick={() => {
                          setSearchTerm("");
                          setFilteredCities([]);
                        }}
                      >
                        <img src={Images && Images.crossIcon} alt="img" />
                      </div>
                    )}
                  </div>
                  <div
                    className={`map-img-trans ${activeButton === "city"
                      ? `${activeclass || searchTerm <= 0 ? "" : "active"}`
                      : ""
                      }`}
                  >
                    <div className="map-img-wrap">
                      <img src={Images && Images.mapImg} alt="img" />
                    </div>
                  </div>
                  <div
                    className={`location-dropdown ${activeButton === "city" && filteredCities?.length !== 0
                      ? `${activeclass ? "" : "active"}`
                      : ""
                      }`}
                  >
                    {filteredCities?.length > 0 ? (
                      <ul>
                        {filteredCities.map((item, index) => (
                          <li key={index} onClick={() => getZipcode(item)}>
                            <span className="direction state">
                              {item.state}
                            </span>
                            <h4 className="location city-loc">{item.city}</h4>
                          </li>
                        ))}
                      </ul>
                    ) : isSearched ? (
                      <p style={{ textAlign: "center" }}>No records found.</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className={`discover-btn ${zipcode ? "" : "disable"}`}>
                <Link
                  to={`${zipcode ? `${basePath}${pagePath}/?zipcode=${zipcode}` : "#"
                    }`}
                  className="discover"
                >
                  Choose This Location
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyCalculator;
