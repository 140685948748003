import React, { useEffect, useState } from "react";
import Images from "../../images";
import "./comfortanalysis.css";
import {
  CardinalCalculatorScene,
  EngineEvents,
  SceneEvents,
} from "cardinalcalculatorscene";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
var API_BASE_URI = window.wp_config.api_uri;

const CalculatorScene = ({
  comfortdata,
  setComfortData,
  chartData,
  openSection,
  isPrescriptiveChecked,
  locationData,
  handleSidebarToggle,
  energyComplianceData,
  isMobile,
  handleexclamationClick,
  visiblePoup,
  handleCloseDiscPopup,
  isLoaded,
  setIsLoaded,
  cardinalCalSceneInstance,
  setCardinalCalSceneInstance,
  containerRef,
}) => {
  const [selectedOption, setSelectedOption] = useState("config3");
  let [activeButton, setActiveButton] = useState("summer");
  const [showDiv, setShowDiv] = useState(false);
  const [selectedloeOption, setSelectedloeOption] = useState(null);
  const [disableradio, setRadioDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const shownCount = Cookies.get("currentStep");
  const [widgetstext, setWidgets] = useState({
    nexttoladyheading: "Blinds fully open",
    nexttolady: "Bright views and very comfortable inside.",
    bottomwidgetstitle: "Comfortable",
    bottomwidgets: "Occupant is always comfortable on summer days.",
    iswomanconfortable: Images.womanImageComfort,
    iseffectshow: false,
    progresbarpostion: "",
  });

  const [winterwidgets, setWinterWidgets] = useState({
    nexttoladyheading: "Near the window",
    nexttolady: "Warm and cozy even close to the window.",
    bottomwidgetstitle: "Comfortable",
    bottomwidgets: "Occupant is always comfortable during winter nights.",
    ladyposition: "slide-right",
    iswomanconfortable: Images.womanImageComfort,
    iseffectshow: false,
    progresbarpostion: "",
  });

  const shieldslogo = {
    "High Solar Gain LoĒ Products": [Images.Logo_180],
    "Medium Solar Gain LoĒ Products": [Images.Logo_272, Images.Logo_270],
  };
  const shields = shieldslogo[comfortdata?.label] || [];

  const energyStarZone = locationData && locationData.energy_star_zone;
  const complianceData =
    energyComplianceData &&
    energyComplianceData?.energy_star_7 &&
    energyComplianceData?.energy_star_7[energyStarZone];

  const [sliderValue, setSliderValue] = useState(0);
  const [isPortrait, setIsPortrait] = useState(null);
  const [progressCount, setProgressCount] = useState("0%");

  useEffect(() => {
    window.scrollTo(0, 0);
    CheckWindowOrientation();
    window.addEventListener("resize", CheckWindowOrientation);
    return () => {
      window.removeEventListener("resize", CheckWindowOrientation);
    };
  }, [isPortrait]);

  function CheckWindowOrientation() {
    let isLandscape = window?.screen?.orientation?.type === "landscape-primary";
    if (
      window.innerWidth < 767 &&
      window.innerWidth < window.innerHeight &&
      !isLandscape
    ) {
      setIsPortrait(true);
    } else {
      setTimeout(() => {
        setIsPortrait(false);
      }, 3000);
    }
  }

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (comfortdata) {
      let filteredata = [];
      const matchingOptions = [];
      Object.entries(chartData && chartData).forEach(([key, value]) => {
        const selectedBar = value?.filter(
          (option) =>
            option?.value === comfortdata?.value &&
            option?.product_label === comfortdata?.label &&
            option?.product_type === comfortdata?.productType
        );
        if (selectedBar?.length === 0 || filteredata?.length === 1) {
          return;
        }
        filteredata.push(selectedBar[0]);
        matchingOptions.push(...selectedBar);
      });
      if (!filteredata[0]?.product_label && !filteredata[0]?.positions) {
        return;
      }
      const { product_label, positions } = filteredata[0];
      let shadeOrProximity = activeButton === "summer" ? "shade" : "proximity";
      const selectedPosition =
        positions?.[getSizeConfig(selectedOption)]?.[shadeOrProximity];
      setTimeout(() => {
        switch (product_label) {
          case "High Solar Gain LoĒ Products":
          case "Medium Solar Gain LoĒ Products":
          case "Low Solar Gain LoĒ Products":
            handleSolarGain(selectedPosition, selectedOption);
            break;
          default:
            console.log("Unknown label:", product_label);
        }
      }, 2000);
      handleOptionClick(matchingOptions[0]);
    }
  }, [comfortdata, isLoaded]);

  useEffect(() => {
    if (isLoaded === true) {
      return;
    }
    if (isPortrait === false && isLoaded === false) {
      let sceneManifestUrl = `${API_BASE_URI}/SceneManifest.json`;
      const sceneConfig = {
        engineConfig: {
          containerID: "cardinal-scene",
          usePreloader: false,
          sceneManifestUrl: sceneManifestUrl,
          onlyLoadResources: false,
        },
        enableDevControls: false,
      };
      const ccs = new CardinalCalculatorScene(sceneConfig);

      // Update the download assets progress
      ccs.on(EngineEvents.ZIP_DOWNLOAD_PROGRESS, ({ progress }) => {
        let progresscount = progress.toFixed(0) + "%";
        setProgressCount(progresscount);
      });
      ccs.on(EngineEvents.SCENE_LOAD_ENDED, () => {
        setIsLoaded(true);
        setTimeout(() => {
          ccs?.addTracking("tracking_character_left", (x, y) => {
            const label = document.getElementById("cardinal-label");
            if (label && label.style) {
              label.style.transform = `translate(${x - 130}px, ${y}px)`;
            }
          });
        }, 500);
      });

      ccs.load();
      setCardinalCalSceneInstance(ccs);
      return () => {
        ccs.off(EngineEvents.ZIP_DOWNLOAD_START);
        ccs.off(EngineEvents.ZIP_DOWNLOAD_PROGRESS);
        ccs.off(EngineEvents.SCENE_LOAD_ENDED);
      };
    }
  }, [isPortrait, isLoaded]);

  useEffect(() => {
    cardinalCalSceneInstance?.on(SceneEvents.CHARACTER_ANIMATION_START, (e) => {
      setRadioDisabled(true);
    });

    cardinalCalSceneInstance?.on(SceneEvents.CHARACTER_ANIMATION_END, (e) => {
      setRadioDisabled(false);
    });
  }, [cardinalCalSceneInstance]);

  const complianceType = getComplianceText(
    openSection,
    isPrescriptiveChecked,
    selectedloeOption,
    complianceData
  );

  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };

  const handleOptionClick = (option) => {
    let selectedWindowConfig = `${option.product_label} of ${option.product_type}`;
    let updatedOption = {
      ...option,
      selectedWindowConfig: selectedWindowConfig,
    };
    setSelectedloeOption(
      selectedWindowConfig === selectedloeOption ? null : updatedOption
    );
  };

  const handleFinishClick = () => {
    handleCloseDiscPopup();
    if (!selectedloeOption) {
      toast.warning("Please select an option.");
      return;
    }
    const { product_label, positions, product_type } = selectedloeOption;
    setComfortData({
      label: product_label,
      productType: product_type,
    });
    const shadeOrProximity = activeButton === "summer" ? "shade" : "proximity";
    const selectedPosition =
      positions?.[getSizeConfig(selectedOption)]?.[shadeOrProximity];

    switch (product_label) {
      case "High Solar Gain LoĒ Products":
      case "Medium Solar Gain LoĒ Products":
      case "Low Solar Gain LoĒ Products":
        handleSolarGain(selectedPosition, selectedOption);
        break;
      default:
        console.log("Unknown label:", product_label);
    }
    setShowDiv(!showDiv);
  };

  const getSizeConfig = (selectedOption) => {
    return selectedOption === "config1"
      ? "small"
      : selectedOption === "config2"
      ? "medium"
      : selectedOption === "config3"
      ? "large"
      : "";
  };
  const handleRadioChange = (event) => {
    let windowconfig = event.target.value;
    setSelectedOption(windowconfig);
    const shadeOrProximity = activeButton === "summer" ? "shade" : "proximity";
    const selectedPosition =
      selectedloeOption?.positions?.[getSizeConfig(event.target.value)]?.[
        shadeOrProximity
      ];
    if (windowconfig === "config1") {
      cardinalCalSceneInstance?.changeWindow("small");
    } else if (windowconfig === "config2") {
      cardinalCalSceneInstance?.changeWindow("medium");
    } else if (windowconfig === "config3") {
      cardinalCalSceneInstance?.changeWindow("large");
    }
    setTimeout(() => {
      handleSolarGain(selectedPosition, event.target.value);
    }, 2000);
  };

  const summerWidgetsConfig = {
    Down: {
      heading: "Blinds fully closed",
      message: "Too hot, must close shades fully, disappointed.",
      title: "Potentially Very Uncomfortable",
      widgetMessage:
        "Occupant must have full shade (overhangs, trees, shades) to feel comfortable.",
      progressBar: "bottom",
      blindsStatus: "close",
      tempEnvironment: "hot",
      characterPosition: "center",
      characterStatus:
        winterwidgets?.selectedPosition === "Back" ? "cold" : "warm",
    },
    Middle: {
      heading: "Blinds half open",
      message: "Not as cool as hoped, adjusting shades helps.",
      title: "Potentially Uncomfortable",
      widgetMessage:
        "Occupant must have some shade (overhangs, trees, shades) to feel comfortable.",
      progressBar: "middle",
      blindsStatus: "semi-open",
      tempEnvironment: "hot",
      characterPosition: "center",
      characterStatus:
        winterwidgets?.selectedPosition === "Back" ? "cold" : "warm",
    },
    Up: {
      heading: "Blinds fully open",
      message: "Bright views and very comfortable inside.",
      title: "Comfortable",
      widgetMessage: "Occupant is always comfortable on summer days.",
      progressBar: "",
      blindsStatus: "open",
      tempEnvironment: "normal",
      characterPosition: "right",
      characterStatus:
        winterwidgets?.selectedPosition === "Back" ? "cold" : "warm",
    },
  };
  const winterWidgetsConfig = {
    Near: {
      nexttoladyheading: "Near the window",
      nexttolady: "Warm and cozy even close to the window.",
      bottomwidgetstitle: "Comfortable",
      bottomwidgets: "Occupant is always comfortable during winter nights.",
      ladyposition: "slide-right",
      progresbarpostion: "",
      selectedPosition: "Near",
      characterPosition: "right",
      tempChange: "normal",
      trackingId: "tracking_character_left",
      trackingOffsetX: -130,
      roomview: "front",
    },
    Center: {
      nexttoladyheading: "Middle of room",
      nexttolady: "Chilly by the windows, warmer in the middle.",
      bottomwidgetstitle: "Fairly Uncomfortable",
      bottomwidgets:
        "Occupant needs a little distance from the window to stay warm.",
      ladyposition: "slide-center",
      progresbarpostion: "center",
      selectedPosition: "Center",
      characterPosition: "center",
      tempChange: "cold",
      trackingId: null,
      trackingOffsetX: null,
      roomview: "front",
    },
    Back: {
      nexttoladyheading: "Far from window",
      nexttolady: "Freezing near windows, move far away for warmth.",
      bottomwidgetstitle: "Very Uncomfortable",
      bottomwidgets:
        "Occupant must retreat to far end of the room to keep warm.",
      ladyposition: "slide-left",
      progresbarpostion: "left",
      selectedPosition: "Back",
      characterPosition: "left",
      tempChange: "cold",
      trackingId: "tracking_character_right",
      trackingOffsetX: 30,
      roomview: "front-right",
    },
  };

  const handleSolarGain = (selectedPosition, selectedOption) => {
    if (!selectedPosition && !selectedOption) return;
    console.log(selectedPosition, selectedOption, "config");
    const summerConfig = summerWidgetsConfig[selectedPosition];
    const winterConfig = winterWidgetsConfig[selectedPosition];
    if (summerConfig) {
      setWidgets((prevState) => ({
        ...prevState,
        nexttoladyheading: summerConfig?.heading,
        nexttolady: summerConfig?.message,
        bottomwidgetstitle: summerConfig?.title,
        bottomwidgets: summerConfig?.widgetMessage,
        progresbarpostion: summerConfig?.progressBar,
        selectedPosition: selectedPosition,
        selectedOption: selectedOption,
      }));
      cardinalCalSceneInstance?.setBlindsStatus(summerConfig?.blindsStatus);
      characterPositionHandler(
        summerConfig?.characterPosition,
        summerConfig?.characterStatus
      );
      setTimeout(() => {
        tempChangeHandler(summerConfig?.tempEnvironment);
        cardinalCalSceneInstance?.addTracking(
          "tracking_character_left",
          (x, y) => {
            const label = document.getElementById("cardinal-label");
            if (label && label.style) {
              label.style.transform = `translate(${x - 130}px, ${y}px)`;
            }
          }
        );
      }, 700);
    }

    if (winterConfig) {
      tempChangeHandler(winterConfig?.tempChange);
      setWinterWidgets((prevState) => ({
        ...winterConfig,
      }));
      characterPositionHandler(winterConfig?.characterPosition, "cold");
      setTimeout(() => {
        cardinalCalSceneInstance?.setView(winterConfig?.roomview);
        if (winterConfig.trackingId) {
          const label = document.getElementById("cardinal-label");
          cardinalCalSceneInstance?.addTracking(
            winterConfig.trackingId,
            (x, y) => {
              if (label && label.style) {
                label.style.transform = `translate(${
                  x + winterConfig.trackingOffsetX
                }px, ${y}px)`;
              }
            }
          );
        }
      }, 1000);
      //console.log(`lady should be ${winterConfig.ladyposition}`);
    }
  };

  const handleChangeSeasonal = (season) => {
    setActiveButton(season);
    activeButton = season;
    if (season === "summer") {
      cardinalCalSceneInstance?.changeSeasonalTime(
        "summer/day",
        1000,
        false,
        false,
        "#000000"
      );
      cardinalCalSceneInstance?.setView("front");
    } else if (season === "winter") {
      cardinalCalSceneInstance?.setBlindsStatus("open");
      cardinalCalSceneInstance?.changeSeasonalTime(
        "winter/night",
        1000,
        false,
        false,
        "#000000"
      );
      tempChangeHandler("normal");
    }
    const shadeOrProximity = activeButton === "summer" ? "shade" : "proximity";
    const selectedPosition =
      selectedloeOption?.positions?.[getSizeConfig(selectedOption)]?.[
        shadeOrProximity
      ];
    setTimeout(() => {
      handleSolarGain(selectedPosition, selectedOption);
    }, 2000);
  };

  const tempChangeHandler = (tempstate) => {
    if (isLoaded && tempstate) {
      cardinalCalSceneInstance?.setTemperatureEnvironment(tempstate);
    }
  };

  const characterPositionHandler = (characterPosition, characterStatus) => {
    let deafultPostion =
      cardinalCalSceneInstance?.state?.currentCharacterPosition;
    if (deafultPostion === characterPosition) return;
    cardinalCalSceneInstance?.moveCharacter(characterPosition, characterStatus);
  };

  const handleSliderChange = (event) => {
    const value = parseFloat(event.target.value);
    setSliderValue(value);
    cardinalCalSceneInstance.slideBetweenViews(value);
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
      Cookies.set("currentStep", currentStep);
    }
  };
  const handleCross = () => {
    setCurrentStep(null);
  };
  const handleStart = () => {
    Cookies.set("currentStep", "0");
    setCurrentStep(null);
  };

  return (
    <div className="comfort">
      <div className="mobile-view-toggle" onClick={handleSidebarToggle}>
        <img src={Images.toggleIconSidebar} alt="" />
        <div className="emergy-cal-location">
          <div className="location_icon">
            <img src={Images && Images.locationIcon} alt="img" />
          </div>
          <h3>
            {locationData.primary_city}, {locationData.state_id}
          </h3>
        </div>
      </div>
      {isLoaded && (
        <div className="vertical-buttons">
          <div className="winter-btn">
            <button
              type="button"
              className={activeButton === "winter" ? "winteractive" : ""}
              onClick={() => handleChangeSeasonal("winter")}
              disabled={disableradio ? true : false}
            >
              <div className="btn-inner">
                <div className="btn-img">
                  {" "}
                  <img src={Images.winterNightIcon} alt="" />
                </div>
                <span> Winter Night</span>
              </div>
            </button>
            <button
              type="button"
              className={activeButton === "summer" ? "active" : ""}
              onClick={() => handleChangeSeasonal("summer")}
              disabled={disableradio ? true : false}
              id="summerbtn"
            >
              {" "}
              <div className="btn-inner">
                <div className="btn-img">
                  <img src={Images.summerIcon} alt="" />
                </div>
                <span> Summer Day</span>
              </div>
            </button>
          </div>
        </div>
      )}
      {/*load app container cardinal-scene*/}
      <div className="screen-roate-icon-wrap">
        <img src={Images.screen_rotate_img} alt="" />
      </div>
      <div ref={containerRef} id="cardinal-scene"></div>

      <div
        className="cardinal-scene-loder"
        id="scene-loder"
        style={{ display: isPortrait === false ? "none" : "" }}
      >
        <div
          className="turn-phone landsacpe-view-trun"
          style={{ display: isPortrait === false ? "none" : "" }}
        >
          <div className="rotate-icon">
            <img src={Images.landscape_rotate_icon} alt="" />
          </div>
          <p>You’re all set</p>
        </div>

        <div className="turn-phone">
          <div className="rotate-icon">
            <img src={Images.phone_rotate_icon} alt="" />
          </div>
          <p>Turn your phone</p>
        </div>
      </div>

      {isPortrait === false && isMobile && (
        <div
          className="cardinal-scene-loderinner"
          style={{ display: isLoaded ? "none" : "" }}
        >
          <div className="loder-gif-wrap">
            <img src={Images.loader_gif} alt="" />
          </div>
          <h4>Loading scene</h4>
          <div className="loading-value">{progressCount}</div>
        </div>
      )}
      {!isLoaded && !isMobile && (
        <div
          className="cardinal-scene-loderinner"
          style={{ display: isLoaded ? "none" : "" }}
        >
          <div className="loder-gif-wrap">
            <img src={Images.loader_gif} alt="" />
          </div>
          <h4>Loading scene</h4>
          <div className="loading-value">{progressCount}</div>
        </div>
      )}

      {isLoaded && (
        <div>
          <div
            className={`binds_box ${
              activeButton === "winter" ? "winterprogress" : ""
            }`}
            id="cardinal-label"
          >
            <div className="blind_rogress">
              <img src={Images.blindProgress} alt="" />
              <span
                className={`progress-icon ${
                  widgetstext.progresbarpostion === "bottom"
                    ? "progress-icon-right"
                    : widgetstext.progresbarpostion === "middle"
                    ? "progress-icon-left"
                    : activeButton === "winter" &&
                      winterwidgets.ladyposition === "slide-left"
                    ? "progress-icon-left"
                    : winterwidgets.ladyposition === "slide-center"
                    ? "progress-icon-right"
                    : ""
                }`}
              >
                <img src={Images.progressIcon} alt="" />
              </span>
            </div>

            <h3>
              {activeButton === "summer"
                ? `${widgetstext.nexttoladyheading}`
                : winterwidgets.nexttoladyheading}
            </h3>
            <p>
              {activeButton === "summer" ? (
                <span>{widgetstext.nexttolady}</span>
              ) : (
                <span>{winterwidgets.nexttolady}</span>
              )}
            </p>
          </div>

          <div className="window-size-wrap">
            {isLoaded && currentStep === 1 && shownCount !== "0" && (
              <div className="comfort-modal comfort-step1">
                <div className="modal-header">
                  <h6>1/3</h6>
                  <button className="close-icon" onClick={handleCross}>
                    <img src={Images.crossIcon} alt="" />
                  </button>
                </div>
                <h3>Choose window size and IG configuration.</h3>

                <button className="next-btn" onClick={handleNext}>
                  Next
                </button>
              </div>
            )}
            {isLoaded && currentStep === 3 && shownCount !== "0" && (
              <div className="comfort-modal comfort-step3">
                <div className="modal-header">
                  <h6>3/3</h6>
                  <button className="close-icon" onClick={handleCross}>
                    <img src={Images.crossIcon} alt="" />
                  </button>
                </div>
                <h3>View the results of your window selection</h3>
                <button className="next-btn" onClick={handleStart}>
                  Let's Start
                </button>
              </div>
            )}
            <div className="window-size-wrap-inners">
              <div className="window-labels">
                <div className="window-label">
                  <h5>Window Configuration</h5>
                </div>
              </div>
              <div className="window-size-wraps">
                {/*disclimer popups */}
                <div
                  className={`ncomfortable-popup ${
                    visiblePoup === 0 ? "show" : ""
                  }`}
                >
                  <div className="ncomfortable-popup-inner">
                    <div className="popup-close" onClick={handleCloseDiscPopup}>
                      <span className="close-icon">
                        <img
                          src={Images.crossIcon}
                          width={10}
                          height={10}
                          alt=""
                        />
                      </span>
                    </div>

                    <h6>
                      Direct solar gain onto the occupant is the primary concern
                      for hot weather discomfort.
                    </h6>
                    <p>
                      Across North America, this means east-facing windows in
                      the morning and west-facing windows in the afternoon will
                      be of concern. Of the two, west is the biggest concern as
                      max solar gain is coincident with max temperature. Summer
                      sun exposure on north and south elevations are minimal.
                    </p>
                  </div>
                  <img
                    src={Images && Images.caretDown}
                    width={20}
                    height={20}
                    alt=""
                    className="caret-down"
                  />
                </div>
                <div
                  className={`ncomfortable-popup ${
                    visiblePoup === 5 ? "show" : ""
                  }`}
                >
                  <div className="ncomfortable-popup-inner">
                    <div className="popup-close" onClick={handleCloseDiscPopup}>
                      <span className="close-icon">
                        <img
                          src={Images.crossIcon}
                          width={10}
                          height={10}
                          alt=""
                        />
                      </span>
                    </div>
                    <h6></h6>
                    <p>
                      The most important metric is U-factor, which measures how
                      much interior heat is lost through the window. The lower
                      the U-factor, the lower the heat loss, and the more likely
                      the user will feel comfortable when close to the window.
                      With higher performance LoĒ coatings and possibly more
                      glass panes, an occupant can drastically reduce U-factor
                      and maintain a comfortable space.
                    </p>
                  </div>
                  <img
                    src={Images && Images.caretDown}
                    width={20}
                    height={20}
                    alt=""
                    className="caret-down"
                  />
                </div>
                <div
                  className={`ncomfortable-popup comfortable-popup ${
                    complianceType === "Non Compliant"
                      ? "iecc-non-compliant"
                      : ""
                  } ${visiblePoup === 1 ? "show" : ""}`}
                >
                  <div className="ncomfortable-popup-inner">
                    <div className="popup-close" onClick={handleCloseDiscPopup}>
                      <span className="close-icon">
                        <img
                          src={Images.crossIcon}
                          width={10}
                          height={10}
                          alt=""
                        />
                      </span>
                    </div>
                    <h6>
                      This application provides guidance based on the 2021
                      International Energy Conservation Code (IECC) for
                      evaluating window and door configurations.
                    </h6>
                    <p>
                      Local building codes may differ from the IECC 2021
                      standards and can vary by state or municipality. For
                      precise compliance details, consult your local code
                      enforcement office. Use this tool for preliminary guidance
                      only, not as a substitute for professional advice.
                    </p>
                  </div>
                  <img
                    src={Images && Images.caretDown}
                    width={20}
                    height={20}
                    alt=""
                    className="caret-down"
                  />
                </div>

                <div className="window-size-inner">
                  <div className="window-size-wrap-inners">
                    <div className="size-type-wrap">
                      <div className="size-type">
                        <div className="size-type-btn">
                          <div
                            className={
                              selectedOption === "config1"
                                ? "activeRadio radioButtons"
                                : "radioButtons"
                            }
                          >
                            <input
                              type="radio"
                              id="config1"
                              name="windowConfig"
                              value="config1"
                              checked={selectedOption === "config1"}
                              onChange={handleRadioChange}
                              disabled={disableradio ? true : false}
                            />
                            <label htmlFor="config1">Small</label>
                          </div>
                          <div
                            className={
                              selectedOption === "config2"
                                ? "activeRadio radioButtons"
                                : "radioButtons"
                            }
                          >
                            <input
                              type="radio"
                              id="config2"
                              name="windowConfig"
                              value="config2"
                              checked={selectedOption === "config2"}
                              onChange={handleRadioChange}
                              disabled={disableradio ? true : false}
                            />
                            <label htmlFor="config2">Medium</label>
                          </div>
                          <div
                            className={
                              selectedOption === "config3"
                                ? "activeRadio radioButtons"
                                : "radioButtons"
                            }
                          >
                            <input
                              type="radio"
                              id="config3"
                              name="windowConfig"
                              value="config3"
                              checked={selectedOption === "config3"}
                              onChange={handleRadioChange}
                              disabled={disableradio ? true : false}
                            />
                            <label htmlFor="config3">Large</label>
                          </div>
                        </div>
                      </div>
                      <h6 className="window-confi-title">
                        Window Configuration
                      </h6>
                      <div
                        className={`solar_glass ${
                          widgetstext.progresbarpostion !== "bottom"
                            ? "active"
                            : ""
                        }`}
                        onClick={isMobile ? toggleDiv : null}
                      >
                        <div className="solar_glass_data">
                          <h5>{comfortdata ? comfortdata.label : ""}</h5>
                          <p>{comfortdata ? comfortdata.productType : ""}</p>
                        </div>

                        <div className="solar_btn_wrap">
                          <div
                            className={`compliant-issue ${
                              activeButton === "summer" &&
                              disableradio &&
                              widgetstext.iseffectshow
                                ? "active"
                                : ""
                            }`}
                          >
                            <img src={Images.exclamationIconCompliant} alt="" />
                            <p>Fix the issue</p>
                          </div>
                          <button
                            type="button"
                            onClick={toggleDiv}
                            disabled={disableradio ? true : false}
                          >
                            <span className="btn-text">Change</span>
                            <span className="arrow-down">
                              <img src={Images.backArrowIcon} alt="" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`compliant-box-wrap ${
                        complianceType === "Non Compliant" ? "active" : ""
                      }`}
                    >
                      <div className="box-wrap compliant-box primary-coating-option">
                        <h5 className="code-compliance-title">
                          Primary Coating Options
                        </h5>
                        <div className="shild-img">
                          <ul className="image-container">
                            {shields.map((logo, index) => (
                              <li
                                key={index}
                                className={
                                  comfortdata.label ===
                                  "Low Solar Gain LoĒ Products"
                                    ? "logo_height"
                                    : ""
                                }
                              >
                                <img
                                  key={index}
                                  src={logo}
                                  alt="img"
                                  className={"desktop-shild-logo"}
                                />
                              </li>
                            ))}
                            {locationData.energy_star_zone === "Northern" &&
                            openSection === 0 &&
                            comfortdata.label ===
                              "Low Solar Gain LoĒ Products" ? (
                              <li>
                                <img
                                  src={Images.Logo_366}
                                  alt="img"
                                  className={"desktop-shild-logo"}
                                />
                              </li>
                            ) : comfortdata.label ===
                              "Low Solar Gain LoĒ Products" ? (
                              <li
                                className={
                                  comfortdata.label ===
                                  "Low Solar Gain LoĒ Products"
                                    ? "logo_height"
                                    : ""
                                }
                              >
                                <img
                                  src={
                                    isMobile
                                      ? Images.shildLast
                                      : Images.primary_coating_shild
                                  }
                                  alt="img"
                                  className={"desktop-shild-logo"}
                                />
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="box-wrap compliant-box">
                        <h5 className="code-compliance-title">
                          Code Compliance
                        </h5>
                        <div className="icon-wrap">
                          <span>
                            <img
                              src={Images.compliantIcon}
                              alt=""
                              className="complian-icon"
                            />
                            <img
                              src={Images.notCompliantIcon}
                              alt=""
                              className="not-complian-icon"
                            />
                          </span>
                          <h6>
                            {complianceType}
                            {openSection !== 0 ? (
                              <div
                                className="exclamationIcon-show-popup"
                                onClick={() => handleexclamationClick(1)}
                              >
                                <img
                                  src={Images.exclamationIcon2}
                                  width={20}
                                  height={20}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </h6>
                        </div>
                        <p>
                          {openSection !== 0 ? (
                            <span>
                              {locationData.IECC21 === "4" &&
                              locationData.Moisture21 === "C" ? (
                                <span>
                                  IECC 2021 Energy Code <br /> Zone 5
                                </span>
                              ) : locationData.IECC21 === "4" ? (
                                <span>
                                  IECC 2021 Energy Code <br /> Zone 4 Marine
                                </span>
                              ) : (
                                <span>
                                  IECC 2021 Energy Code <br /> Zone{" "}
                                  {locationData.iecc21_moisture21}
                                </span>
                              )}
                            </span>
                          ) : (
                            <span>
                              ENERGY STAR 7 Program <br />
                              {locationData.energy_star_zone}{" "}
                              {isPrescriptiveChecked &&
                              selectedloeOption &&
                              locationData.energy_star_zone == "Northern" &&
                              selectedloeOption?.nothern_compliance
                                ?.prescriptive_path === "Yes"
                                ? "Prescriptive Path"
                                : selectedloeOption?.nothern_compliance
                                    ?.energy_equivalent_path === "Yes" &&
                                  locationData.energy_star_zone == "Northern"
                                ? "Energy Equivalent Path"
                                : "Zone"}
                            </span>
                          )}
                        </p>
                      </div>
                      <div
                        className={`box-wrap comfortable  ${
                          activeButton === "winter"
                            ? winterwidgets.progresbarpostion === ""
                              ? "show winter-gradient"
                              : winterwidgets.progresbarpostion === "center"
                              ? "show winter-gradientyellow"
                              : winterwidgets.progresbarpostion === "left"
                              ? "show winter-gradientred"
                              : ""
                            : widgetstext.progresbarpostion === ""
                            ? "show winter-gradient"
                            : widgetstext.progresbarpostion === "middle"
                            ? "show winter-gradientyellow adjustwidth"
                            : widgetstext.progresbarpostion === "bottom"
                            ? "show winter-gradientred adjustwidth"
                            : ""
                        }`}
                      >
                        <div className="compliant-labels">
                          <h5>Comfort Result</h5>
                        </div>
                        <div className="icon-wrap">
                          <span className="comfortable-icon">
                            {activeButton === "winter" ? (
                              <img
                                src={
                                  winterwidgets.progresbarpostion === ""
                                    ? Images.womancomfortable
                                    : winterwidgets.progresbarpostion ===
                                      "center"
                                    ? Images.womanfairlyicon
                                    : winterwidgets.progresbarpostion === "left"
                                    ? Images.womanveryunconfort
                                    : Images.womancomfortable
                                }
                                alt=""
                                className="icon2"
                              />
                            ) : (
                              <img
                                src={
                                  widgetstext.progresbarpostion === ""
                                    ? Images.womancomfortable
                                    : widgetstext.progresbarpostion === "middle"
                                    ? Images.womanfairlyicon
                                    : widgetstext.progresbarpostion === "bottom"
                                    ? Images.womanveryunconfort
                                    : Images.womancomfortable
                                }
                                alt=""
                                className="icon2"
                              />
                            )}
                          </span>

                          <h6>
                            {activeButton === "summer"
                              ? widgetstext.bottomwidgetstitle
                              : winterwidgets.bottomwidgetstitle}
                            {activeButton === "summer" &&
                            widgetstext.bottomwidgetstitle !== "Comfortable" ? (
                              <div
                                className="exclamationIcon-show-popup"
                                onClick={() => handleexclamationClick(0)}
                              >
                                <img
                                  src={Images.exclamationIcon2}
                                  width={20}
                                  height={20}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {activeButton === "winter" &&
                            winterwidgets.bottomwidgetstitle !==
                              "Comfortable" ? (
                              <div
                                className="exclamationIcon-show-popup"
                                onClick={() => handleexclamationClick(5)}
                              >
                                <img
                                  src={Images.exclamationIcon2}
                                  width={20}
                                  height={20}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </h6>
                        </div>
                        <p>
                          {activeButton === "summer"
                            ? widgetstext.bottomwidgets
                            : winterwidgets.bottomwidgets}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*end container */}

      {/*Change Window Configuratio Model  */}
      {showDiv && (
        <div className="window_config_wrap">
          <div className="window_config">
            <div className="change-window-title">
              <h3>Change Window Configuration</h3>
              <button type="button" onClick={handleFinishClick}>
                Finish
              </button>
            </div>

            <div className="window_cards_container">
              {Object.entries(chartData && chartData).map(([key, value]) => {
                let configshields = shieldslogo[value[0].product_label] || [];
                return (
                  <div key={key} className="card">
                    <h6>{value[0].product_label}</h6>
                    <div className="shild-img">
                      <ul className="image-container">
                        {configshields.map((logo, index) => (
                          <li
                            key={index}
                            className={
                              value[0].product_label ===
                              "Low Solar Gain LoĒ Products"
                                ? "logo_height"
                                : ""
                            }
                          >
                            <img
                              src={logo}
                              alt="img"
                              className={"desktop-shild-logo"}
                            />
                          </li>
                        ))}
                        {locationData.energy_star_zone === "Northern" &&
                        openSection === 0 &&
                        value[0].product_label ===
                          "Low Solar Gain LoĒ Products" ? (
                          <li>
                            <img
                              src={Images.Logo_366}
                              alt="img"
                              className={"desktop-shild-logo"}
                            />
                          </li>
                        ) : value[0].product_label ===
                          "Low Solar Gain LoĒ Products" ? (
                          <li
                            className={
                              value[0].product_label ===
                              "Low Solar Gain LoĒ Products"
                                ? "logo_height"
                                : ""
                            }
                          >
                            <img
                              src={Images.shildLast}
                              alt="img"
                              className={"desktop-shild-logo"}
                            />
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>

                    <div className="pane_btns">
                      {value.map((option) => {
                        return (
                          <div
                            key={option.product_type}
                            onClick={() => handleOptionClick(option)}
                            className={
                              selectedloeOption &&
                              selectedloeOption.selectedWindowConfig ===
                                `${option.product_label} of ${option.product_type}`
                                ? `selected option ${
                                    openSection === 1
                                      ? option.compliance === "Yes"
                                        ? "compliant"
                                        : "non-compliant case1"
                                      : openSection === 0 &&
                                        isPrescriptiveChecked
                                      ? option.nothern_compliance
                                          .prescriptive_path === "Yes"
                                        ? "compliant"
                                        : option.nothern_compliance
                                            .energy_equivalent_path === "Yes"
                                        ? locationData.energy_star_zone ==
                                          "Northern"
                                          ? "energyeqivalent case5"
                                          : "compliant"
                                        : "non-compliant"
                                      : option.nothern_compliance
                                          .energy_equivalent_path === "Yes"
                                      ? "compliant"
                                      : "non-compliant case3"
                                  }`
                                : `option ${
                                    openSection === 1
                                      ? option.compliance === "Yes"
                                        ? "compliant"
                                        : "non-compliant case4"
                                      : openSection === 0 &&
                                        isPrescriptiveChecked
                                      ? option.nothern_compliance
                                          .prescriptive_path === "Yes"
                                        ? "compliant"
                                        : option.nothern_compliance
                                            .energy_equivalent_path === "Yes"
                                        ? locationData.energy_star_zone ==
                                          "Northern"
                                          ? "energyeqivalent case5"
                                          : "compliant"
                                        : "non-compliant"
                                      : option.nothern_compliance
                                          .energy_equivalent_path === "Yes"
                                      ? "compliant"
                                      : "non-compliant case6"
                                  }`
                            }
                          >
                            {option.product_type}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="chart-type-list window-configuration energy-zone-selected">
              {openSection !== 0 ? (
                locationData.IECC21 === "4" &&
                locationData.Moisture21 === "C" ? (
                  <h3 className="selected-zone-title iecc">
                    Compliant with <strong>IECC 2021 Zone 5</strong>
                  </h3>
                ) : locationData.IECC21 === "4" ? (
                  <h3 className="selected-zone-title iecc">
                    Compliant with <strong>IECC 2021 Zone 4 Marine</strong>
                  </h3>
                ) : (
                  <h3 className="selected-zone-title iecc">
                    Compliant with{" "}
                    <strong>
                      IECC 2021 Zone {locationData.iecc21_moisture21}
                    </strong>
                  </h3>
                )
              ) : (
                <h3 className="selected-zone-title">
                  Compliant with{" "}
                  <strong>
                    ENERGY STAR 7 {locationData.energy_star_zone} Zone
                  </strong>
                </h3>
              )}
              <ul className="checkbox-custom reactangle-checkbox">
                <li>
                  <label className="non-compliant">
                    <h4 className="not-recommended">(Not Recommended)</h4>
                    <span className="checkmark">Non-Compliant Products</span>
                  </label>
                </li>
                {openSection === 0 &&
                complianceData?.energy_equivalent_path &&
                complianceData?.energy_equivalent_path ? (
                  <li>
                    <label className="compliant">
                      <span className="checkmark">Prescriptive Path</span>
                    </label>
                  </li>
                ) : (
                  <li>
                    <label className="compliant">
                      <span className="checkmark">Compliant Products</span>
                    </label>
                  </li>
                )}
                {openSection === 0 &&
                complianceData?.energy_equivalent_path &&
                complianceData?.energy_equivalent_path ? (
                  <li>
                    <label className="compliant">
                      <span className="checkmark checkmark-equivalent">
                        Energy Equivalent Path
                      </span>
                    </label>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* showing Steps */}
      <div>
        {isLoaded && currentStep === 2 && shownCount !== "0" && (
          <div className="comfort-modal comfort-step2">
            <div className="modal-header">
              <h6>2/3</h6>
              <button className="close-icon" onClick={handleCross}>
                <img src={Images.crossIcon} alt="" />
              </button>
            </div>
            <h3>Toggle between Summer Day and Winter Night</h3>

            <button className="next-btn" onClick={handleNext}>
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculatorScene;

function getComplianceText(
  openSection,
  isPrescriptiveChecked,
  selectedloeOption,
  complianceData
) {
  if (openSection === 1) {
    return selectedloeOption && selectedloeOption.compliance === "Yes"
      ? "Compliant"
      : "Non Compliant";
  } else if (openSection === 0 && isPrescriptiveChecked) {
    return selectedloeOption &&
      selectedloeOption.nothern_compliance.prescriptive_path === "Yes"
      ? complianceData?.energy_equivalent_path &&
        complianceData?.energy_equivalent_path
        ? "Compliant"
        : "Compliant"
      : selectedloeOption?.nothern_compliance.energy_equivalent_path === "Yes"
      ? "Compliant"
      : "Non Compliant";
  } else {
    return selectedloeOption &&
      selectedloeOption.nothern_compliance.energy_equivalent_path === "Yes"
      ? "Compliant"
      : "Non Compliant";
  }
}
